import axios from "axios";

export const MetamaskAuth = async (data) => {
  let resp = null;
  const token = localStorage.getItem("token");
  const res = await axios.post(process.env.REACT_APP_API_AUTH_URL + "/rest/auth/login", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (res.data && res.status === 200) {
    resp = res.data;
  } else {
    throw new Error(res?.data?.message || "Metamask Auth api Error");
  }
  return resp;
};

export const GoogleAuth = async (data) => {
  let resp = null;
  const res = await axios.post(process.env.REACT_APP_API_AUTH_URL + "/google/frontend-callback", data);
  if (res.data && res.status === 200) {
    resp = res.data;
  } else {
    throw new Error(res?.data?.message || "Google Auth api Error");
  }
  return resp;
};

export const QuotePrice = async (price) => {
  try {
    const res = await axios({
      method: "GET",
      url: process.env.REACT_APP_CROSSMINT_QUOTE_API,
      params: {
        nativePrice: price,
        quoteCurrency: "usd"
      },
      headers: {'x-client-id': process.env.REACT_APP_CROSSMINT_CLIENT_ID}
    });
    return res.data;
  } catch (e) {
    console.log("QUOTE PRICE ERR: ", e);
    return null;
  }
}

export const ApiCall = async (method = "GET", path, data = {}) => {
  try {
    const res = await axios({
      method,
      url: process.env.REACT_APP_API_DATA_URL + path,
      data
    });
    return res.data;
  } catch (e) {
    if(e.response.status === 500) return null;
    else {
      console.log("API GET ERR: "+path, e);
      return null;
    }
  }
};

export const MultiApiCall = async (method = "GET", paths) => {
  let requests = [];
  paths.forEach(path => {
    requests.push(
      axios({
        method,
        url: process.env.REACT_APP_API_DATA_URL + path
      })
    )
  });

  try {
    const res = await Promise.allSettled(requests).then(axios.spread((...responses) => {
      return responses;
    }));
    return res;
  } catch (e) {
    if(e.response.status === 500) return null;
    else {
      console.log("API GET ERR: "+ paths, e);
      return null;
    }
  }
};

export const EmailApiCall = async (emailSubject, emailBody) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_DATA_URL + "/rest/email-sender/getListed",
      data: {
        mailSubject: emailSubject,
        mailBody: emailBody
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    throw e
  }
}

export const GetUserData = async () => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios({
      method: "GET",
      url: process.env.REACT_APP_API_DATA_URL + "/rest/user-service/getUserData",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    if(e.response.data.name === "UNAUTHORIZED") {
      return "expired";
    } else {
      console.log("AUTH ERR: ", e);
      return e;
    }
  }
}