import { createContext, useContext, useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { isExpired } from "react-jwt";
import { toast } from 'react-toastify';
import axios from "axios";

import { MetamaskAuth, GoogleAuth } from "../Utils/ApiUtils";
import signMessage from "../Utils/signer";
import { handleChainChange } from "../Utils/common";

import { providerHandler } from "../web3/contractInteraction";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { status, connect, account } = useMetaMask();
    
    const [loginState, setLoginState] = useState(false);
    const [metamaskStatus, setMetamaskStatus] = useState("initializing");
    const [googleStatus, setGoogleStatus] = useState("notConnected");
    const [loginModal, setLoginModal] = useState(false);

    const loginToast = "loginToast";

    useEffect(() => {
        setMetamaskStatus(status);
    }, [status]);

    useEffect(() => {
        const userType = localStorage.getItem("userType");
        
        if (userType && userType === "metamask") {
            const token = localStorage.getItem("token");
            const tokenExpired = isExpired(token);
            console.log("EXP M", tokenExpired);

            if(!tokenExpired && status === "connected" && (account === localStorage.getItem("account").toLowerCase())) {
                (async () => {
                    await handleChainChange();
                    const account = await providerHandler();
                    setLoginState({type: "metamask", account: account});
                })();
            } else if (status === "connected") {
                setLoginState(false);
                authLogout();
            }
        }
    }, [account]);

    useEffect(() => {
        const userType = localStorage.getItem("userType");
        
        if (userType && userType === "google") {
            const token = localStorage.getItem("token");
            const tokenExpired = isExpired(token);
            console.log("EXP G", tokenExpired);

            if (!tokenExpired) {
                const userInfo = JSON.parse(localStorage.getItem("account"));
                setGoogleStatus("connected");
                setLoginState({type: "google", account: userInfo.name});
            } else {
                setGoogleStatus("notConnected");
                setLoginState(false);
                authLogout();
            }
        }
    }, []);

    const metamaskLogin = async () => {
        setMetamaskStatus("connecting");
        await handleChainChange();
        const res = await connect();
        await providerHandler();

        try {
            if (account !== null) {
                const signature = await signMessage(res[0]);
                const JWT = await MetamaskAuth(signature);

                if (JWT) {
                    localStorage.setItem("userType", "metamask");
                    localStorage.setItem("token", JWT.tokenGenerated);
                    localStorage.setItem("account", account);
    
                    setLoginState({ type: "metamask", account: account });
                    setLoginModal(false);
                }
            }
        } catch (e) {
            setMetamaskStatus("notConnected");
            toast(e, {
                type: 'error',
                toastId: loginToast
            });
        }
    };

    const googleLoginHandler = async (res) => {
        setGoogleStatus("connecting");
        const account = JSON.parse(localStorage.getItem("account"));
        const token = localStorage.getItem("token");
        const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: { Authorization: `Bearer ${res.access_token}` }}).then(info => info.data);

        if ((res && !token) || (res && account.sub !== userInfo.sub)) {
            try {
                const token = res;
                const JWT = await GoogleAuth(token);
    
                localStorage.setItem("userType", "google");
                localStorage.setItem("token", JWT.tokenGenerated);
                localStorage.setItem("account", JSON.stringify(userInfo));

                setGoogleStatus("connected");
                setLoginState({type: "google", account: userInfo.name});
                setLoginModal(false);
            } catch (e) {
                toast(e.message, {
                    type: 'error',
                    toastId: loginToast
                });
                setGoogleStatus("notConnected");
            }
        } else if (token && account === userInfo.sub) {
            setGoogleStatus("connected");
            setLoginState({type: "google", account: userInfo.name});
            setLoginModal(false);
        } else {
            setGoogleStatus("notConnected");
            authLogout();
        }
    }

    const googleLogin = useGoogleLogin({
        flow: 'implicit',
        onSuccess: (tokenResponse) => {googleLoginHandler(tokenResponse)},
        onError: (e) => {console.log("GOOGLE LOGIN ERR: ", e)}
    });

    const authLogout = async (reload) => {
        const userType = localStorage.getItem("userType");

        if (userType === "google") {
            googleLogout();
            setGoogleStatus("notConnected");
        }

        localStorage.removeItem("token");
        localStorage.removeItem("account");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("userType");

        setLoginState(false);
        setLoginModal(false);

        if (reload) {
            window.location.reload();
        }
    }

    return <AuthContext.Provider value={{
        loginState,
        loginModal,
        metamaskStatus,
        googleStatus,
        setLoginModal,
        metamaskLogin,
        googleLogin,
        authLogout
    }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};