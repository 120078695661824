import React, { useState, useEffect, useRef } from 'react';
import { ApiCall, QuotePrice } from '../../Utils/ApiUtils';

import "./quickBuyModal.scss";

import propCircle from "../../Assets/prop-dashed-circle.svg";
import propPlus from "../../Assets/prop-plus.svg";
import { MdClose } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import { BsCreditCard2BackFill } from "react-icons/bs";

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import Counter from '../Counter/Counter';

import { mint } from '../../web3/contractInteraction';
import { handleChainChange } from '../../Utils/common';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import DataPlaceholder from '../DataPlaceholder/DataPlaceholder';
import { useAuth } from '../../Hooks/useAuth';

export default function QuickBuyModal({modalState, setModalState}) {
    const navigate = useNavigate();
    const { loginState, setLoginModal } = useAuth();

    const [artworkData, setArtworkData] = useState(null);
    const [count, setCount] = useState(1);
    const [quantity, setQuantity] = useState(0);
    const [canBuy, setCanBuy] = useState(false);
    const [mintLoader, setMintLoader] = useState(false);

    const mintToast = "mintToast";

    const mintHandler = async () => {
        if(loginState && loginState.type === "metamask") {
          try {
            setMintLoader(true);
            await handleChainChange();
    
            console.log("MINT: ", artworkData.price, localStorage.getItem("account"), artworkData.tokenId, count);
            await mint(artworkData.price, localStorage.getItem("account"), artworkData.tokenId, count);
            toast("Purchase Successful!", {
                type: "success",
                toastId: mintToast
            });
            await getQuantity();
            setCount(1);
    
            setMintLoader(false);
            navigate("/artwork/"+modalState);
            setModalState(false);
          } catch (e) {
            console.log("MINT ERR: ", e.message, 'CODE:', e.code);

            toast(
                e.code === "INSUFFICIENT_FUNDS"
                    ? "Wallet does not have enough balance"
                    : e.message.includes("ERC20: transfer amount exceeds balance")
                    ? "Not enough token balance"
                    : e.code === "ACTION_REJECTED"
                    ? "Transaction Rejected"
                    : "Something went wrong :(",
                {
                    type: "error",
                    toastId: mintToast
                }
            );

            setMintLoader(false);
          }
        } 
        
        else if (!loginState) {
          setLoginModal(true);
        }
    }

    const getQuantity = async () => {
        const artworkQuantity = await ApiCall("GET", "/rest/contract-interaction/getTokenLeft/"+modalState);
        setQuantity(artworkQuantity.numberOfItemLeft);
    }

    const getArtworkData = async () => {
        try {
            const data = await ApiCall("GET", "/rest/artwork/getArtworkById/"+modalState);
            const artworkImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+data.imageRef);
            const priceUSD = await QuotePrice(data.price);
      
            setArtworkData({
              tokenId: data.tokenId,
              image: artworkImage,
              title: data.title,
              description: data.description,
              traits: data.traits,
              price: data.price,
              priceUSD: priceUSD ? "$"+priceUSD.price : null,
              quantity: data.quantity,
              artist: {
                id: data.artistId.id,
                name: data.artistId.name,
                country: data.artistId.country,
                description: data.artistId.description
              }
            });
        } catch (e) {
            console.log("QUICK BUY ERR: ", e);
        }
    }

    useEffect(() => {
        setCount(1);
        if(modalState)
            getArtworkData();
    }, [modalState]);

    // let crossmintOverlay;
    
    // useEffect(() => {
    //     console.log(crossmintOverlay);
    // }, [crossmintOverlay]);

    // const crossmintListner = () => {
    //     crossmintOverlay = document.getElementById("__crossmint-overlay__");
    // }
    
    return (
        <div className="quick-buy-screen" style={modalState ? null : {opacity: 0, pointerEvents: "none"}}>
            <div className="quick-buy-container" style={modalState ? null : {opacity: 0, transform: "translateY(10%)"}}>
                <button className="close-btn" onClick={() => {setModalState(false); setArtworkData(null); setCanBuy(false);}}>
                    <MdClose />
                </button>

                {artworkData ? <>
                    <img src={artworkData.image} alt={artworkData.title} />

                    <div className="info-section">
                        <h1 onClick={() => {setModalState(false); setArtworkData(null);}}>
                            <Link to={"/artwork/"+modalState}>
                                <span className="title">{artworkData.title}</span> 
                                {/* <span className="token-id">#{artworkData.tokenId}</span> */}
                            </Link>
                        </h1>
                        <p className="artist-name" onClick={() => {setModalState(false); setArtworkData(null);}}>By <Link to={"/artist/"+artworkData.artist.id}>{artworkData.artist.name}</Link></p>
                        <p className="description">{artworkData.description}</p>

                        <div className="buy-info">
                            <p className="price">
                                Price <br/> 
                                <span className="eth">{artworkData.price} ETH</span> <span className="usd">{artworkData.priceUSD}</span>
                            </p>
                            <div className="qty">
                                {canBuy && "Quantity"}
                                <Counter 
                                    artworkId={modalState}
                                    setCanBuy={setCanBuy}
                                    maxCount={quantity}
                                    setMaxCount={setQuantity}
                                    price={0} 
                                    count={count} 
                                    setCount={setCount} 
                                    initialQuantity={artworkData.quantity}
                                />
                            </div>
                        </div>
                        
                        <span style={!canBuy || quantity === 0 ? {opacity: 0, pointerEvents: 'none', height: '0rem'} : null}>
                            {loginState && loginState.type === "google" ?
                                <div className="cross-mint-btn">
                                    <BsCreditCard2BackFill /> Buy Now
                                    <CrossmintPayButton
                                        clientId={process.env.REACT_APP_CROSSMINT_CLIENT_ID}
                                        environment={process.env.REACT_APP_CROSSMINT_ENVIRONMENT}
                                        mintConfig={{
                                            "type":" erc-1155",
                                            "totalPrice": (artworkData.price * count).toString(),
                                            "_id": artworkData.tokenId,
                                            "_amount": count
                                        }}
                                    />
                                </div> : 
                                <button className={!loginState ? "buy-btn no-anim" : "buy-btn"} onClick={mintHandler}>{loginState.type === "metamask" && <FaEthereum />}Buy Now</button>
                            }
                        </span>
                    </div>
                </> : <DataPlaceholder type={"quickbuy"} alternate/>}
            </div>
                <div className="mint-loader" style={mintLoader ? null : {opacity: 0, pointerEvents: 'none', transform: 'translateY(1rem)'}}>
                    <div className="prop-container">
                        <img src={propCircle} alt="prop" className="circle" />
                        <img src={propPlus} alt="prop" className="plus" />
                    </div>
                </div>
        </div>
    )
}

// export const quickBuy = () => {
//     setModalState(true);
// }