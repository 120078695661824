import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "./manageArtwork.scss";

import { useAdminData } from "../../../Hooks/useAdminData";
import { MultiApiCall } from "../../../Utils/ApiUtils";

import Footer from '../../../Components/Footer/Footer';

export default function ManageArtwork() {
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState("published");
    const [artworkData, setArtworkData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [pageNumber, setPageNumber] = useState(1);

    const { data, fetching, hasMore } = useAdminData(currentTab, "artwork", pageNumber);

    const getPageData = async () => {
        if(pageNumber === 1) setArtworkData(null);
        if (data) {
            setDataLoading(true);
            const artworkData = data;

            let artworkTableData = [];
            let itemsSold = 0;
            
            if (currentTab === "published") {
                let requests = [];
                for (let i = 0; i < artworkData.length; i++) {
                    requests.push("/rest/contract-interaction/getArtworkSold/"+artworkData[i].id);
                }
                itemsSold = await MultiApiCall("GET", requests);
            }
            
            for(let i = 0; i < artworkData.length; i++) {        

                artworkTableData.push({
                    id: artworkData[i].id,
                    title: artworkData[i].title,
                    country: artworkData[i].country,
                    description: artworkData[i].description,
                    quantity: artworkData[i].quantity,
                    price: artworkData[i].price,
                    date: artworkData[i].createdAt,
                    itemsSold: currentTab === "published" ? itemsSold[i].value.data.numberOfItemSold : null
                });
            }
    
            setArtworkData(artworkTableData);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        setArtworkData(null);
        setPageNumber(1);
    }, [currentTab]);

    useEffect(() => {
        getPageData();
    }, [data]);

    return (
        <div className="manage-artwork">
            <div className="header">
                <h1>Manage Artworks</h1>
                <Link to={"/admin/manage-artists"}><h2>Manage Artists</h2></Link>
            </div>

            <div className="sub-header">
                <h2 className={currentTab === "published" ? "active" : ""} onClick={() => setCurrentTab("published")}>Published</h2>
                <h2 className={currentTab === "draft" ? "active" : ""} onClick={() => setCurrentTab("draft")}>Drafts</h2>
            </div>
            {artworkData && artworkData.length > 0 ?
                <table className="artwork-table">
                    <thead>
                        <tr>
                            <th>Artwork Name</th>
                            <th>Quantity</th>
                            <th style={currentTab === "published" ? null : {gridColumn: 'span 2'}}>Description</th>
                            {currentTab === "published" ? <th>Items Sold</th> : null}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {artworkData.map((artwork, i) => {return (
                                <tr key={i}>
                                    <td className="name">{artwork.title}</td>
                                    <td className="quantity">{artwork.quantity}</td>
                                    <td className="description" style={currentTab === "published" ? null : {gridColumn: 'span 2'}}><span>{artwork.description}</span></td>
                                    {currentTab === "published" ? <td className="sold-modified">{artwork.itemsSold}</td> : null}
                                    <td className="action"><button onClick={() => navigate("/admin/edit-artwork/"+artwork.id)}>{currentTab === "published" ? "Edit" : "Complete"}</button></td>
                                </tr>
                            )}) 
                        }
                        {hasMore && <tr className="load-more">
                            <td colSpan="5"><p onClick={() => setPageNumber(pageNumber+1)}>Load More</p></td>
                        </tr>}
                    </tbody>
                </table> :
                <div className="no-data">
                {currentTab === "published" && !(dataLoading || fetching) && <>
                    <p>You have not published any artwork yet.</p>
                    <button onClick={() => navigate("/admin/add-artwork")}>Add Now</button>
                </>}
                {currentTab === "draft" && !(dataLoading || fetching) && <>
                    <p>No drafts so far.</p>
                </>}
                {(dataLoading || fetching) && !artworkData && <p>Fetching {currentTab} artworks...</p>}
            </div>
        }
            <Footer />
         </div>
    )
}
