import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMetaMask } from "metamask-react";
import { useAuth } from '../../Hooks/useAuth';
import { toast } from 'react-toastify';

import "./loginModal.scss";

import { FcGoogle } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import { ReactComponent as MetaMask } from "../../Assets/MetaMask.svg";

export default function LoginModal() {
    const { account } = useMetaMask();
    const currentPath = useLocation();

    const { 
        loginState,
        loginModal,
        metamaskStatus,
        setLoginModal,
        googleStatus,
        metamaskLogin, 
        googleLogin, 
        authLogout 
    } = useAuth();

    const loginToast = "loginToast";

    // useEffect(() => {
    //     console.log("GOOGLE STATUS: ", googleStatus);
    //     console.log("LOGIN STATE: ", loginState);
    // }, [loginState, googleStatus]);

    return (
        <div className="login-modal-screen" style={loginModal ? {'opacity': 1, 'pointerEvents': 'unset'} : null}>
            <div className="login-modal-container" style={loginModal ? {'transform': 'translateY(0%)'} : null}>
                <h1 className="logo">SUPERMETA STORE</h1>
                <button className="close-btn" onClick={() => setLoginModal(false)}>
                    <MdClose />
                </button>
                <div className="login-container">
                    {loginState ? 
                        <span className="logout">
                            <p>Are you sure you want to logout?</p>
                            <button className="logout-btn" onClick={() => {authLogout(currentPath.pathname.includes("/admin"))}}>
                                Logout
                            </button>
                            <button className="cancel-btn" onClick={() => setLoginModal(false)}>
                                Cancel
                            </button>
                        </span> :

                        <span className="login-all">
                            <button className="metamask-btn" onClick={() => {
                                if(metamaskStatus !== "unavailable") {
                                    metamaskLogin();
                                }
                                else toast("Install Metamask to continue", {type: 'warning', toastId: loginToast});
                            }}>
                                <MetaMask />
                                {account ? "Authenticate Metamask" : "Sign in with Metamask"}
                            </button>

                            <button className="google-btn" onClick={() => {
                                if(googleStatus !== "unavailable") {
                                    googleLogin(); 
                                }
                                else toast("Enable third pary cookies to continue", {type: 'warning', toastId: loginToast});
                            }}>
                                <FcGoogle />
                                Sign in with Google
                            </button>

                            <p>By signing in, you agree to <a href="https://www.supermetaplus.com/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a> of our platform</p>
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}
