import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import AOS from "aos";

import "./app.scss";
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Discover from "./Pages/Discover/Discover";
import ArtworkProfile from "./Pages/ArtworkProfile/ArtworkProfile";
import ArtistProfile from "./Pages/ArtistProfile/ArtistProfile";
import LoginModal from "./Components/LoginModal/LoginModal";
import NotFound from "./Pages/NotFound/NotFound";
import QuickBuyModal from "./Components/QuickBuyModal/QuickBuyModal";
import Artists from "./Pages/Artists/Artists";
import Artworks from "./Pages/Artworks/Artworks";
import FAQ from "./Pages/FAQ/FAQ";
import About from "./Pages/About/About";
import GetListed from "./Pages/GetListed/GetListed";
import MobileOverlay from "./Components/MobileOverlay/MobileOverlay";

import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Login from "./Pages/Admin/Login/Login";
import AddArtist from "./Pages/Admin/AddArtist/AddArtist";
import EditArtist from "./Pages/Admin/EditArtist/EditArtist";
import AddArtwork from "./Pages/Admin/AddArtwork/AddArtwork";
import EditArtwork from "./Pages/Admin/EditArtwork/EditArtwork";
import ManageArtists from "./Pages/Admin/ManageArtists/ManageArtists";
import ManageArtwork from "./Pages/Admin/ManageArtwork/ManageArtwork";

function App() {
  const [adminLoginState, setAdminLoginState] = useState(false);

  const [quickBuyModal, setQuickBuyModal] = useState(false);

  const route = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: true
    });
  }, []);

  const makeToast = () => {
    toast.success("Transaction Successful!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
    });
    toast("Switch to Ethereum Mainnet", {
      position: "top-center",
      type: "warning",
      autoClose: 5000,
      hideProgressBar: true
    });
    toast.error(
      "Insufficient balance.",
      {
        position: "top-center",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: true,
      }
    );
  }

  return (
    <div className="app">
      <Navbar />
        <Routes>
          <Route index path='/' element={<Home quickBuy={setQuickBuyModal}/>} />
          <Route index path='/discover' element={<Discover quickBuy={setQuickBuyModal}/>} />
          <Route path='/artwork/:id' element={<ArtworkProfile quickBuy={setQuickBuyModal}/>} />
          <Route path='/artist/:id' element={<ArtistProfile quickBuy={setQuickBuyModal}/>} />
          <Route path='/artists' element={<Artists />} />
          <Route path='/artists/:searchQuery' element={<Artists />} />
          <Route path='/artworks' element={<Artworks quickBuy={setQuickBuyModal}/>} />
          <Route path='/artworks/:searchQuery' element={<Artworks quickBuy={setQuickBuyModal}/>} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/about' element={<About />} />
          <Route path='/get-listed' element={<GetListed />} />
          <Route path='/*' element={<NotFound />} />
          
          {/* ADMIN ROUTES */}
          <Route index path='/admin/' element={<Login adminLoginState={adminLoginState} setAdminLoginState={setAdminLoginState}/>} />
          <Route element={<ProtectedRoute adminLoginState={adminLoginState}/>}>
            <Route path='/admin/add-artist' element={<AddArtist />} />
            <Route path='/admin/edit-artist/:id' element={<EditArtist />} />
            <Route path='/admin/add-artwork' element={<AddArtwork />} />
            <Route path='/admin/edit-artwork/:id' element={<EditArtwork/>} />
            <Route path='/admin/manage-artists' element={<ManageArtists />} />
            <Route path='/admin/manage-artworks' element={<ManageArtwork />} />
          </Route>
        </Routes>
      <QuickBuyModal modalState={quickBuyModal} setModalState={setQuickBuyModal} />
      <LoginModal />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        transition={Slide}
      />
      <MobileOverlay />
    </div>
  );
}

export default App;
