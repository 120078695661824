import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiCall } from '../../Utils/ApiUtils';

import "./artistCard.scss";
import blankProfile from "../../Assets/Blank-Profile.png";

export default function ArtistCard({id, image, name, country, description, alternate}) {
  const navigate = useNavigate();

  const [imageUri, setImageUri] = useState(null);

  const getImageUri = async () => {
    const artistImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+image);
    setImageUri(artistImage ? artistImage : blankProfile);
  }

  useEffect(() => {
    setImageUri(null);
    getImageUri();
  }, [image]);

  return (
    <div className={`artist-card ${alternate ? "alternate" : ''}`} onClick={() => navigate("/artist/"+id)}>
        {imageUri ? 
          <img src={imageUri} alt={name} onClick={() => navigate("/artist/"+id)}/> : 
          <div className="img-loader" onClick={() => navigate("/artist/"+id)}></div>
        }
        <Link to={"/artist/"+id} className="name"><h1>{name}</h1></Link>
        <h1 className="country">{country.slice(0, 2).toUpperCase()}</h1>
        <div className="description">
          <p>{description}</p>
        </div>
    </div>
  )
}
