import React, { useState, useEffect } from 'react';

import "./artistProfile.scss";

import blankProfile from "../../Assets/Blank-Profile.png";

import { FaTwitter, FaDiscord, FaInstagram, FaDeviantart, FaArtstation, FaBehance, FaDribbble, FaGlobe } from "react-icons/fa";

import ArtworkCard from '../../Components/ArtworkCard/ArtworkCard';
import Footer from "../../Components/Footer/Footer";
import DataPlaceholder from "../../Components/DataPlaceholder/DataPlaceholder";

import { useNavigate, useParams } from 'react-router-dom';
import { ApiCall } from '../../Utils/ApiUtils';

export default function ArtistProfile({ quickBuy }) {
    const { id: artistId } = useParams();
    const navigate = useNavigate();

    const [artistData, setArtistData] = useState(null);
    const [artCards, setArtCards] = useState(null);

    useEffect(() => {
        getPageData();
    }, []);

    const getPageData = async () => {
        const data = await ApiCall("GET", "/rest/artist/getArtistById/"+artistId);
        if (data) {
            const artistImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+data.image);
    
            setArtistData({
                image: artistImage,
                name: data.name,
                country: data.country,
                description: data.description,
                discord: data.discord,
                twitter: data.twitter,
                website: data.website,
                instagram: data.instagram,
                deviantArt: data.deviantArt,
                artStation: data.artStation,
                behance: data.behance,
                dribbble: data.dribble
            });
    
            let artworkCards = [];
            const cardData = await ApiCall("GET", "/rest/artwork/getArtworkByArtist/"+artistId+"?limit=5").then(res => {return (res.artworkModel)});
    
            for (let i = 0; i < cardData.length; i++) {                
                artworkCards.push({
                    image: cardData[i].imageRef,
                    id: cardData[i].id,
                    title: cardData[i].title,
                    artist: data.name,
                    country: data.country,
                    price: cardData[i].price
                });
            }
    
            setArtCards(artworkCards);
        } else {
            navigate("/artist/"+artistId+"/not-found");
        }
    }

    return (
        <div className="artist">
            <section className="info-section">
                {artistData ? <>
                    <img src={artistData.image ? artistData.image : blankProfile} alt="artist" />
                    <div className="artist-info">
                        <h1 className="name" data-aos="fade-right">{artistData.name} <span>{artistData.country.slice(0, 2).toUpperCase()}</span></h1>
                        <p className="description" data-aos="fade-right" data-aos-delay="150">{artistData.description}</p>
                        <div className="social-bar" data-aos="fade-right" data-aos-delay="300">
                            {artistData.twitter ? <a href={artistData.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter /></a> : null}
                            {artistData.discord ? <a href={artistData.discord} target="_blank" rel="noopener noreferrer"><FaDiscord /></a> : null}
                            {artistData.instagram ? <a href={artistData.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram /></a> : null}
                            {artistData.deviantArt ? <a href={artistData.deviantArt} target="_blank" rel="noopener noreferrer"><FaDeviantart /></a> : null}
                            {artistData.artStation ? <a href={artistData.artStation} target="_blank" rel="noopener noreferrer"><FaArtstation /></a> : null}
                            {artistData.behance ? <a href={artistData.behance} target="_blank" rel="noopener noreferrer"><FaBehance /></a> : null}
                            {artistData.dribbble ? <a href={artistData.dribbble} target="_blank" rel="noopener noreferrer"><FaDribbble /></a> : null}
                            {artistData.website ? <a href={artistData.website} target="_blank" rel="noopener noreferrer"><FaGlobe /></a> : null}
                        </div>
                    </div>
                </> : <DataPlaceholder type="artist" alternate/>}
            </section>

            <section className="artwork-section">
                <div className="title">
                    <h1>Artworks {artistData ? "by " + artistData.name : null}</h1>
                    {artistData && <button onClick={() => navigate(`/artworks/${artistData.name}`)}>Browse More</button>}
                </div>
                <div className="art-grid">
                {artCards ? artCards.map((card, i) => {
                    return (
                        <ArtworkCard
                            id={card.id} 
                            image={card.image}
                            title={card.title} 
                            artist={card.artist} 
                            country={card.country} 
                            price={card.price}
                            quickBuy={quickBuy}
                            key={i}
                        />
                    )
                }) : [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card"/>}) }
                </div>
            </section>

            <Footer />
        </div>
    )
}
