import React from 'react';
import Footer from '../../Components/Footer/Footer';

import "./about.scss"

export default function About() {
  return (
    <div className="about">
        <div className="header">
            <h1>About Us</h1>
        </div>

        <div className="content">
            <p>
                SUPERMETA STORE is a curated global store featuring NFTs, physical and digital artworks, collectibles and merchandise from selected creators in Supermeta's portfolio.
            </p>
            <p>
                It is designed to be a playground, focusing on delivering the most exceptional assortment of goods at the leading edge of culture. 
            </p>

            <h2>About Supermeta</h2>
            <p>
                Supermeta creates phenomenal NFT experiences with exceptional creators and brands.
            </p>

            <div className="btn-container">
                <a href="https://www.supermetaplus.com/" target="_blank" rel="noopener noreferrer">Visit Supermeta</a>
            </div>
        </div>

        <Footer />
    </div>
  )
}
