import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiCall } from '../../Utils/ApiUtils';

import "./artworkCard.scss";
import blankArt from "../../Assets/Blank-Art.png";

import { FaEthereum } from "react-icons/fa";
import { BsCreditCard2BackFill } from "react-icons/bs";

export default function ArtworkCard({id, image, title, artist, country, price, quickBuy, alternate}) {
  const loginState = localStorage.getItem("userType");
  const navigate = useNavigate();

  const [imageUri, setImageUri] = useState(null);

  const getImageUri = async () => {
    const artworkImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+image);
    setImageUri(artworkImage ? artworkImage : blankArt);
  }

  useEffect(() => {
    setImageUri(null);
    getImageUri();
  }, [image]);

  return (
    <div className={`art-card ${alternate ? "alternate" : ''}`}>
        {imageUri ? 
          <img src={imageUri} alt={title} onClick={() => navigate("/artwork/"+id)}/> : 
          <div className="img-loader" onClick={() => navigate("/artwork/"+id)}></div>
        }
        <h2>{artist}</h2>
        <Link to={"/artwork/"+id} className="name"><h1>{title}</h1></Link>
        <h1 className="country">{country.slice(0, 2).toUpperCase()}</h1>
        <div className="buy-info">
          <h1>{price} ETH</h1>
          <button onClick={() => quickBuy(id)} className={!loginState ? "no-anim" : ""}>
            {loginState === "google" && <BsCreditCard2BackFill />}
            {loginState === "metamask" && <FaEthereum />}
            <span>Buy Now</span>
          </button>
        </div>
    </div>
  )
}
