import React, { useState, useEffect } from 'react';

import "./home.scss";

import propCircle from "../../Assets/prop-dashed-circle.svg";
import propPlus from "../../Assets/prop-plus.svg";

import Footer from '../../Components/Footer/Footer';
import ArtworkCard from '../../Components/ArtworkCard/ArtworkCard';
import ArtistCard from '../../Components/ArtistCard/ArtistCard';

import { ApiCall } from '../../Utils/ApiUtils';
import { useNavigate } from 'react-router-dom';
import DataPlaceholder from '../../Components/DataPlaceholder/DataPlaceholder';

export default function Home({ quickBuy }) {
  const [artworkCards, setArtworkCards] = useState(null);
  const [artistCards, setArtistCards] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    const featuredData = await ApiCall("GET", "/rest/featuredList/getFeaturedList/10/5");
    const artworkData = featuredData.artwork;
    const artistData = featuredData.artist;

    let artistCardsData = [];
    let artworkCardData = [];

    for(let i = 0; i < artworkData.length; i++) {      
      artworkCardData.push({
        id: artworkData[i].id,
        image: artworkData[i].imageRef,
        title: artworkData[i].title,
        artist: artworkData[i].artistName,
        country: artworkData[i].country,
        price: artworkData[i].price
      })
    }
    
    for(let i = 0; i < artistData.length; i++) {      
      artistCardsData.push({
        id: artistData[i].id,
        image: artistData[i].image,
        name: artistData[i].name,
        country: artistData[i].country,
        description: artistData[i].description
      })
    }

    setArtworkCards(artworkCardData);
    setArtistCards(artistCardsData);
  }

  return (
    <div className="home">
        <section className="intro">
            <div className="intro-text">
                <h1 data-aos="fade-right">Launch Edition</h1>
                <p data-aos="fade-right" data-aos-delay="100">Explore high quality digital artworks from curated artists, <br /> handpicked by the Supermeta team.</p>
                <button data-aos="fade-right" data-aos-delay="200" onClick={() => navigate("/discover")}>Explore</button>
            </div>

            <div className="circle-prop">
              <img src={propCircle} alt="prop" className="circle" />
              <img src={propPlus} alt="prop" className="plus" />
            </div>
        </section>

        
        <section className="ft-artwork">
          <div className="section-header">
            <h1>Featured Artworks</h1>
            <button className="more" onClick={() => navigate("/artworks")}>Browse More</button>
          </div>

          <div className="card-grid">
            {artworkCards ? artworkCards.map((card, i) => {
              return (
                <ArtworkCard 
                  id={card.id}
                  image={card.image} 
                  title={card.title} 
                  artist={card.artist} 
                  country={card.country} 
                  price={card.price}
                  quickBuy={quickBuy}
                  key={i}
                />
              )
            }) : [...Array(10).keys()].map((i) => {return <DataPlaceholder key={i} type="card"/>}) }
          </div>
        </section>

        <section className="ft-artist">
        <div className="section-header">
            <h1>Featured Artists</h1>
            <button className="more" onClick={() => navigate("/artists")}>Browse More</button>
          </div>

          <div className="card-grid">
            {artistCards ? artistCards.map((card, i) => {
              return (
                <ArtistCard 
                  id={card.id}
                  image={card.image}
                  name={card.name}
                  country={card.country}
                  description={card.description}
                  key={i}
                />
              )
            }) : [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card"/>}) }
          </div>
        </section>

        <Footer />
    </div>
  )
}
