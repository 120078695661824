import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import "./getListed.scss";

import countries from "../../Utils/countries";
import Select from 'react-select';

import { MdError } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";
import { GrSend } from "react-icons/gr";

import Footer from "../../Components/Footer/Footer";

import { EmailApiCall, GetUserData } from '../../Utils/ApiUtils';

import { getListedSchema } from '../../Utils/validationSchema';
import { getListedEmail } from '../../Utils/CreateEmail';
import { useAuth } from '../../Hooks/useAuth';

export default function GetListed() {
    const { loginState, setLoginModal, authLogout } = useAuth();

    const [loading, setLoading] = useState(true);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [validationError, setValidationError] = useState({
        name: false,
        email: false,
        country: false,
        portfolio: false,
        remarks: false
    });
    let getListedToast = "getListedToast";

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!loginState)
            setLoginModal(true);
        else {
            setValidationError({
                name: false,
                email: false,
                country: false,
                portfolio: false,
                remarks: false
            });

            let formData = {
                name: event.target.name.value,
                email: event.target.email.value,
                country: event.target.country.value,
                portfolio: event.target.portfolio.value,
                remarks: event.target.remarks.value 
            }

            // console.log(formData);
            getListedToast = toast.loading("Validating", {
                toastId: getListedToast,
            });
            try {
                await getListedSchema.validate(formData, {
                    abortEarly: false
                });
                setLoading(true);
                toast.update(getListedToast, {
                    toastId: getListedToast,
                    render: "Sending", 
                    isLoading: true
                });

                const emailSubject = `Request received from ${formData.name}`;
                const emailBody = getListedEmail(formData.name, formData.email, formData.country, formData.portfolio, formData.remarks);
                await EmailApiCall(emailSubject, emailBody).then(resp => {
                    console.log(resp);
                    toast.update(getListedToast, {
                        render: "Request sent successfully.", 
                        type: 'success', 
                        isLoading: false,
                        closeButton: true,
                        autoClose: 3000
                    });
                }).catch(e => {
                    console.log("EMAIL ERR: ", e);
                    toast.update(getListedToast, {
                        toastId: getListedToast,
                        render: e.response.data.message, type: 'error', 
                        isLoading: false,
                        closeButton: true,
                        autoClose: 3000
                    });
                });
                
            } catch (e) {
                if (e.toString().includes("ValidationError")) {
                    let validationErr = {}
                    for (let i = 0; i < e.inner.length; i++) {
                        validationErr[e.inner[i].path] = e.inner[i].message;
                    }
                    setValidationError(validationErr);
                    toast.update(getListedToast, {
                        toastId: getListedToast,
                        render: "Please fill required fields correctly", 
                        type: 'error', 
                        isLoading: false,
                        closeButton: true,
                        autoClose: 3000
                    });
                }
                else {
                    toast.update(getListedToast, {
                        toastId: getListedToast,
                        render: e.message, 
                        type: 'error', 
                        isLoading: false,
                        closeButton: true,
                        autoClose: 3000
                    });
                    console.log(e);
                }
            }

            await getUserAuth();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const getUserAuth = async () => {
        if (loginState) {
            const data = await GetUserData();
            if (data !== "expired")
                setRequestSubmitted(data);
            else
                authLogout();
        } else {
            setRequestSubmitted(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        getUserAuth();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [loginState]);

    return (
        <div className="get-listed">
            {!loading ?
                requestSubmitted ?
                    <>
                        <div className="header">
                            <h1>Get Listed</h1>
                            <p>Get listed as a Creator and gain access to Supermeta's ecosystem and community. Register your interest below to find out more.</p>
                        </div>

                        <div className="submit-message">
                            <GrSend data-aos="fade-up-right" data-aos-easing="ease-in-out-back" data-aos-duration="1000" data-aos-delay="500"/>
                            <h2>Your request is submitted. Hold on tight, we'll get back soon.</h2>
                        </div>
                    </> :
                    <>
                        <div className="header">
                            <h1>Get Listed</h1>
                            <p>Get listed as a Creator and gain access to Supermeta's ecosystem and community. Register your interest below to find out more.</p>
                        </div>

                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <span className="name">
                                    <label htmlFor="name">Name *</label>
                                    <input type="text" name="name" />
                                    {validationError.name && <span className="error">
                                        <MdError />
                                        {validationError.name}
                                    </span>}
                                </span>
                                <span className="email">
                                    <label htmlFor="email">Email *</label>
                                    <input type="email" name="email" />
                                    {validationError.email && <span className="error">
                                        <MdError />
                                        {validationError.email}
                                    </span>}
                                </span>
                                <span className="country">
                                    <label htmlFor="country">Country *</label>
                                    <Select
                                        options={countries}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => `${option.name} (${option.code})`}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        unstyled
                                        placeholder={"Select Country"}
                                        name="country"
                                    />
                                    {validationError.country && <span className="error">
                                        <MdError />
                                        {validationError.country}
                                    </span>}
                                </span>
                                <span className="portfolio">
                                    <label htmlFor="portfolio">Portfolio Link *</label>
                                    <input type="text" name="portfolio" />
                                    {validationError.portfolio && <span className="error">
                                        <MdError />
                                        {validationError.portfolio}
                                    </span>}
                                </span>

                                <span className="remarks">
                                    <label htmlFor="remarks">Additional Remarks *</label>
                                    <textarea name="remarks" cols="30" rows="6" placeholder='Additional Remarks (Max 500 characters)' />
                                    {validationError.remarks && <span className="error">
                                        <MdError />
                                        {validationError.remarks}
                                    </span>}
                                </span>

                                <div className="btn-container">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </> :   
                <div className="loader">
                    <AiOutlineLoading />
                </div>
            }
            <Footer />
        </div>
    )
}
