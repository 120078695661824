import axios from "axios";
import { useState, useEffect } from "react";

export const useAdminData = (progressType, searchType, pageNumber) => {
    const limit = 5;
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    const searchPath = progressType === "draft" ? 
        "/rest/draft/getDraft" : 
            searchType === "artwork" ? 
                "/rest/global-search/getGlobalArtworkSearchResult" : 
                    "/rest/global-search/getGlobalArtistSearchResult";
  
    useEffect(() => {
        setFetching(true);
        setError(false);

        let cancel;

        axios({
            method: "GET",
            url: process.env.REACT_APP_API_DATA_URL + searchPath,
            params: {pageNumber: pageNumber, limit: limit},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {
            if (pageNumber > 1) {
                setData((prevData => {
                    return [...new Set([...prevData, ...res.data[searchType+"Data"]])] 
                }));
            }
            else {
                setData(res.data[searchType+"Data"]);
            }
            if(progressType === "published")
                setHasMore(res.data[searchType+"TotalLength"] > pageNumber * limit);
            else
                setHasMore(res.data[searchType+"DraftCount"] > pageNumber * limit);
            setFetching(false);
        }).catch(e => {
            if(axios.isCancel(e)) return
            else {setError(true); console.log("SEARCH ERR: ", e)}
        })
        return () => cancel()
    }, [progressType, searchType, pageNumber]);
  
    return { fetching, error, data, hasMore }
  } 