import React from 'react';

import "./mobileOverlay.scss";

import propCircle from "../../Assets/prop-dashed-circle.svg";
import propPlus from "../../Assets/prop-plus.svg";

export default function MobileOverlay() {
  return (
    <div className="mobile-overlay">
        <div className="header">
          <h1 className="logo">SUPERMETA STORE</h1>
        </div>
        <p>This website is compatible with large screen sizes. Please view it on desktop for best experience.</p>

        <div className="circle-prop">
            <img src={propCircle} alt="prop" className="circle" />
            <img src={propPlus} alt="prop" className="plus" />
        </div>
    </div>
  )
}
