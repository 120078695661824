import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import { ApiCall, QuotePrice } from '../../Utils/ApiUtils'

import './adminTransactionModal.scss'

import { AiOutlineLoading } from "react-icons/ai";

export default function AdminTransactionModal ({ modalState, setModalState }) {
    const [gasFee, setGasFee] = useState(false);

    const getGasFee = async () => {
        const gas = await ApiCall("GET", "/rest/contract-interaction/getGasPrice/"+modalState.tokenId);
        const usd = await QuotePrice(gas);
        setGasFee({eth: gas.toFixed(5), usd: usd.price});
    }

    useEffect(() => {
        if (modalState)
            getGasFee();
        else
            setTimeout(() => {
                setGasFee(false);
            }, 500);
    }, [modalState]);

    return (
        <div className="modal-screen" style={modalState ? {opacity: 1, pointerEvents: 'unset'} : null}>
            <div className="modal-container" style={modalState ? {opacity: 1, transform: 'translateY(0)'} : null}>
                {gasFee ? <>
                    <p>This action will trigger smart contract interaction.</p>
                    <h2>
                        Transaction Fees ~ {gasFee?.eth} ETH <span>${gasFee?.usd}</span>
                    </h2>
                    <p>Are you sure want to continue?</p>
                    <div className="btn-container">
                        <button className="continue-btn" onClick={() => {
                            modalState.method(modalState.formData, modalState.event);
                            setModalState(false);
                        }}>Continue</button>
                        <button className="cancel-btn" onClick={() => {
                            toast.update(modalState.toastRef, {
                                render: "Action rejected",
                                type: 'warning',
                                isLoading: false,
                                closeButton: true,
                                autoClose: 3000,
                            });
                            setModalState(false);
                        }}>Cancel</button>
                    </div>
                </> : <div className="loader">
                    <AiOutlineLoading />
                    <h2>Fetching Gas Fee</h2>
                </div>}
            </div>
        </div>
    )
}