import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';

import "./discover.scss";

import { RxCaretSort } from "react-icons/rx";
import { FaEthereum } from "react-icons/fa";
import { BsCreditCard2BackFill } from "react-icons/bs";
import blankArt from "../../Assets/Blank-Art.png";

import ArtworkCard from '../../Components/ArtworkCard/ArtworkCard';
import Footer from "../../Components/Footer/Footer";
import DataPlaceholder from "../../Components/DataPlaceholder/DataPlaceholder";
import DropdownInput from '../../Components/DropdownInput/DropdownInput';

import { ApiCall, MultiApiCall, QuotePrice } from '../../Utils/ApiUtils';

export default function Discover({ quickBuy }) {
    const navigate = useNavigate();
    const loginState = localStorage.getItem("userType");

    const [trendingCards, setTrendingCards] = useState(null);

    const [discoverDays, setDiscoverDays] = useState(30);
    const [discoverItems, setDiscoverItems] = useState(null);
    const [discoverSorting, setDiscoverSorting] = useState({ key: null, ascending: false });
    const [discoverNull, setDiscoverNull] = useState(false);

    useEffect(() => {
        getTrendingData();
    }, []);

    useEffect(() => {
        getDiscoverData();
    }, [discoverDays]);

    useEffect(() => {
        if(discoverSorting.key) {
            // console.log("UNSORTED: ", discoverItems);
            // console.log("KEY: ", discoverSorting.key);
            const discoverCopy = [...discoverItems];
        
            const sortedDiscoverItems = discoverCopy.sort((a, b) => {
                return a[discoverSorting.key] - (b[discoverSorting.key]);
            });
        
            setDiscoverItems(
                discoverSorting.ascending ? sortedDiscoverItems : sortedDiscoverItems.reverse()
            );
        }
    }, [discoverSorting]);

    const getTrendingData = async () => {
        setTrendingCards(null);
        const trendingData = await ApiCall("GET", "/rest/artwork/getTrendingArtwork/5");

        let artworkCardData = [];
    
        for(let i = 0; i < trendingData.length; i++) {          
          artworkCardData.push({
            id: trendingData[i].id,
            image: trendingData[i].imageRef,
            title: trendingData[i].title,
            artist: trendingData[i].artistName,
            country: trendingData[i].country,
            price: trendingData[i].price
          })
        }
    
        setTrendingCards(artworkCardData);
    }

    const getDiscoverData = async () => {
        setDiscoverNull(false);
        setDiscoverItems(null);

        const discoverData = await ApiCall("GET", "/rest/artwork/getListedArtworkByDate/"+discoverDays);

        if(discoverData.length > 0) {
            const quotePrice = await QuotePrice(process.env.REACT_APP_CROSSMINT_ENVIRONMENT === "staging" ? 0.01 : 0.1);
            const priceUSD = (quotePrice ? 
                                process.env.REACT_APP_CROSSMINT_ENVIRONMENT === "staging" ?
                                    quotePrice.price * 100 : quotePrice.price * 10
                                : null);

            let discoverItemsData = [];
            let discoverItemsImages;

            let imageRequests = [];
            for (let i = 0; i < discoverData.length; i++) {
                imageRequests.push("/rest/file-manager/getUrlById/"+discoverData[i].imageRef);
            }
            discoverItemsImages = await MultiApiCall("GET", imageRequests);

            for (let i = 0; i < discoverData.length; i++) {
                discoverItemsData.push({
                    id: discoverData[i].id,
                    tokenId: discoverData[i].tokenId,
                    image: discoverItemsImages[i].value.data,
                    title: discoverData[i].title,
                    description: discoverData[i].description,
                    price: discoverData[i].price,
                    priceUSD: quotePrice ? "$"+(discoverData[i].price*priceUSD).toFixed(2) : null,
                    date: Date.parse(discoverData[i].createdAt),
                    artist: {
                        name: discoverData[i].artistName,
                        id: discoverData[i].artistId
                    }
                });
            }
            setDiscoverItems(discoverItemsData);
        } else setDiscoverNull(true);
    }

    const convertDate = (timestamp) => {
        return (
            new Date(timestamp).toLocaleString('UTC', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            }).replaceAll("-", " ")
        )
    }

    return (
        <div className="discover">
            <section className="trending-section">
                <h1 className='t-heading'>Trending</h1>
                <div className="trend-card-container">
                    {trendingCards ? trendingCards.map((card, i) => {
                        return (
                            <ArtworkCard 
                                id={card.id}
                                image={card.image} 
                                title={card.title} 
                                artist={card.artist} 
                                country={card.country} 
                                price={card.price}
                                quickBuy={quickBuy}
                                alternate
                                key={i}
                            />
                        )
                    }) : [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card" alternate/>})}
                </div>
            </section>
            <section className="discover-section">
                <div className="d-heading">
                    <h1>Discover</h1>
                    <DropdownInput 
                        options={[
                            {value: 30, title: "Month"},
                            {value: 7, title: "Week"},
                            {value: 1, title: "24h"}
                        ]}
                        value={discoverDays}
                        setValue={setDiscoverDays}
                        discoverStyle
                    />
                </div>
                {!discoverNull ?
                    <table className="discover-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Artwork</th>
                                <th></th>
                                <th>Description</th>
                                <th>
                                    Price 
                                    {discoverItems ? <RxCaretSort 
                                        onClick={() => 
                                            setDiscoverSorting({ key: "price", ascending: !discoverSorting.ascending })
                                        }
                                    /> : null}
                                </th>
                                {/* <th>
                                    Published 
                                    {discoverItems ? <RxCaretSort 
                                        onClick={() => 
                                            setDiscoverSorting({ key: "date", ascending: !discoverSorting.ascending })
                                        }
                                    /> : null}
                                </th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {discoverItems ? discoverItems.slice(0,10).map((item, i) => {
                                return (
                                    <tr data-aos="fade-right" key={i}>
                                        <td className="serial">{i+1}.</td>
                                        <td><Link to={"/artwork/"+item.id}><img src={item.image ? item.image : blankArt} alt={item.title}/></Link></td>
                                        <td className="name">
                                            <h2>
                                                <Link to={"/artwork/"+item.id}>
                                                    <span className="title">{item.title}</span>
                                                    {/* <span className="token-id">#{item.tokenId}</span> */}
                                                </Link>
                                            </h2>
                                            <p>By <Link to={"/artist/"+item.artist.id}>{item.artist.name}</Link></p>
                                        </td>
                                        <td className="description"><p>{item.description}</p></td>
                                        <td className="price">{item.price} ETH<br/><span>{item.priceUSD}</span></td>
                                        {/* <td className="date">{convertDate(item.date)}</td> */}
                                        <td>
                                            <button className="buy-btn" onClick={() => quickBuy(item.id)}>
                                                {loginState === "google" ? <BsCreditCard2BackFill /> : <FaEthereum />}
                                                <span>Buy Now</span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }) : [...Array(3).keys()].map((i) => {return <DataPlaceholder key={i} type="discover" alternate />}) }
                        </tbody>
                    </table> : 
                    <div className="discover-null">
                        <p>No artworks in the selected timeframe.</p>
                    </div>
                }
                {discoverItems ? <button className="browse-btn" onClick={() => navigate("/artworks")}>Browse More</button> : null}
            </section>

            <Footer />
        </div>
    )
}
