import React, { useState, useEffect } from 'react';

import "./dropdownInput.scss";

import { MdKeyboardArrowDown } from "react-icons/md";

export default function DropdownInput({options, value, setValue, discoverStyle}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);

    useEffect(() => {
        setValue(options[0].value);
    }, []);

    useEffect(() => {
        if(value) {
            const option = options.findIndex(option => option.value === value);
            setSelectedOption(option);
        }
    }, [value]);

    if(discoverStyle) 
        return (
            <div className="dropdown-discover" onClick={() => {setIsOpen(!isOpen)}}>
                {options && options[selectedOption]?.title}
                <MdKeyboardArrowDown style={isOpen ? {transform: 'rotate3d(1, 0, 0, 180deg)'} : null}/>
                <ul style={isOpen ? null : {transform: 'translateY(-10%)', opacity: 0, pointerEvents: 'none'}}>
                    {options.map((option, i) => {
                        return  <li 
                                    className="option"
                                    onClick={() => {
                                        setValue(option.value);
                                    }} 
                                    key={i}
                                >
                                    {option.title}
                                </li>
                    })}
                </ul>
            </div>
        )
    else
        return (
            <div className="dropdown" onClick={() => {setIsOpen(!isOpen)}}>
                {options && options[selectedOption]?.title}
                <MdKeyboardArrowDown style={isOpen ? {transform: 'rotate3d(1, 0, 0, 180deg)'} : null}/>
                <ul style={isOpen ? null : {transform: 'translateY(-10%)', opacity: 0, pointerEvents: 'none'}}>
                    {options.map((option, i) => {
                        return  <li 
                                    className="option"
                                    onClick={() => {
                                        setValue(option.value);
                                    }} 
                                    key={i}
                                >
                                    {option.title}
                                </li>
                    })}
                </ul>
            </div>
        )
}
