import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMetaMask } from "metamask-react";

import { AdminAuth } from '../../../Utils/AdminApiUtils';

import "./login.scss";

import { FcGoogle } from "react-icons/fc";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";
import { ReactComponent as MetaMask } from "../../../Assets/MetaMask.svg";
import { toast } from 'react-toastify';
import { useAuth } from '../../../Hooks/useAuth';

export default function Login({ adminLoginState, setAdminLoginState }) {
  const navigate = useNavigate();
  const {
    loginState,
    googleStatus,
    metamaskStatus,
    metamaskLogin,
    googleLogin,
    authLogout
  } = useAuth();
  const { account } = useMetaMask();
  
  const loginToast = "loginToast";
  
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    setAuthLoading(true);
    const userType = localStorage.getItem("userType");
    const userToken = localStorage.getItem("token");

    if (loginState) {
      if (userType === "metamask") {
        (async () => {
          const isAdmin = await AdminAuth("GET", userToken);
          if (isAdmin && isAdmin !== "expired") {
            setAdminLoginState(true);
            localStorage.setItem("adminToken", userToken);
          } else if (isAdmin === "expired") {
            toast.error("Session Expired, Login Again.", {toastId: loginToast});
            authLogout();
          } else {
            setAdminLoginState(false);
            setAuthLoading(false);
          }
          // console.log("IS ADMIN METAMASK: ", isAdmin);
        })();
      } else if (userType === "google") {
        (async () => {
          const isAdmin = await AdminAuth("GET", userToken);
          if (isAdmin && isAdmin !== "expired") {
            setAdminLoginState(true);
            localStorage.setItem("adminToken", userToken);
          } else if (isAdmin === "expired") {
            toast.error("Session Expired, Login Again.", {toastId: loginToast});
            authLogout()
          } else {
            setAdminLoginState(false);
            setAuthLoading(false);
          }
          // console.log("IS ADMIN GOOGLE: ", isAdmin);
        })();
      }
    } else {
      setAdminLoginState(false);
      localStorage.removeItem("adminToken");
      setAuthLoading(false);
    }
  }, [loginState]);

  useEffect(() => {
    if(adminLoginState) {
      navigate("/admin/manage-artists");
    }
  }, [adminLoginState]);
  
  return (
    <section className="login">
        <h1>Admin Login</h1>
        {authLoading || googleStatus === "initializing" || googleStatus === "connecting" || metamaskStatus === "initializing" || metamaskStatus === "connecting" ? 
          <span className="auth-loader">
            <AiOutlineLoading />
            <p>Checking Credentials</p>
          </span> :
          <>
            {!adminLoginState ?
              !loginState ? 
                <span className="login-all">
                    <button className="metamask-btn" onClick={() => {
                      if(metamaskStatus !== "unavailable") metamaskLogin(); 
                      else toast("Install Metamask to continue", {type: 'warning', toastId: loginToast});
                    }}>
                        <MetaMask />
                        {account ? "Authenticate Metamask" : "Sign in with Metamask"}
                    </button>

                    <button className="google-btn" onClick={() => {
                      if(googleStatus !== "unavailable") googleLogin();
                      else toast("Enable third pary cookies to continue", {type: 'warning', toastId: loginToast});
                    }}>
                        <FcGoogle />
                        Sign in with Google
                    </button>
                </span> : 
                <span className="logout">
                  <p>
                    <MdErrorOutline />
                    Account does not have required access.
                  </p>

                  <button className="logout-btn" onClick={() => authLogout()}>
                    Logout
                  </button>
                  <button className="cancel-btn" onClick={() => navigate("/")}>
                    Return Home
                  </button>
                </span> : 
              null
            } 
          </>
        }
    </section>
  )
}