import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiCall, QuotePrice } from '../../Utils/ApiUtils';

import "./artworkProfile.scss";
import blankArt from "../../Assets/Blank-Art.png";
import blankArtist from "../../Assets/Blank-Profile.png";

import propCircle from "../../Assets/prop-dashed-circle.svg";
import propPlus from "../../Assets/prop-plus.svg";
import { ReactComponent as Opensea } from "../../Assets/opensea.svg";
import { FaEthereum } from "react-icons/fa";
import { BsCreditCard2BackFill } from "react-icons/bs";

import ArtworkCard from '../../Components/ArtworkCard/ArtworkCard';
import Footer from '../../Components/Footer/Footer';
import Counter from "../../Components/Counter/Counter";
import DataPlaceholder from "../../Components/DataPlaceholder/DataPlaceholder";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import { mint } from '../../web3/contractInteraction';
import { toast } from 'react-toastify';
import { handleChainChange } from '../../Utils/common';
import { useAuth } from '../../Hooks/useAuth';

export default function ArtworkProfile({ quickBuy }) {
  const { id: artworkId } = useParams();
  const navigate = useNavigate();
  const { loginState, setLoginModal } = useAuth();

  const [artworkData, setArtworkData] = useState(null);
  const [artCards, setArtCards] = useState(null);

  const [mintLoader, setMintLoader] = useState(false);

  const [count, setCount] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [canBuy, setCanBuy] = useState(false);

  const mintToast = "mintToast";

  useEffect(() => {
    setArtworkData(null);
    setArtCards(null);
    getPageData();
  }, [artworkId]);

  const getQuantity = async () => {
    const artworkQuantity = await ApiCall("GET", "/rest/contract-interaction/getTokenLeft/"+artworkId);
    setQuantity(artworkQuantity.numberOfItemLeft);
  }

  const getPageData = async () => {
    const data = await ApiCall("GET", "/rest/artwork/getArtworkById/"+artworkId);
    if (data) {
      const artworkImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+data.imageRef);
      const artistImage = await ApiCall("GET", "/rest/file-manager/getUrlById/"+data.artistId.image);
      const priceUSD = await QuotePrice(data.price);

      setArtworkData({
        tokenId: data.tokenId,
        image: artworkImage,
        title: data.title,
        description: data.description,
        traits: data.traits,
        price: data.price,
        priceUSD: priceUSD ? "$"+priceUSD.price : null,
        quantity: data.quantity,
        artist: {
          image: artistImage,
          id: data.artistId.id,
          name: data.artistId.name,
          country: data.artistId.country,
          description: data.artistId.description
        }
      });

      let artworkCards = [];
      const cardData = await ApiCall("GET", "/rest/artwork/getArtworkByArtist/"+data.artistId.id+"?limit=5").then(res => {return (res.artworkModel)});;

      for (let i = 0; i < cardData.length; i++) {
        
        artworkCards.push({
          image: cardData[i].imageRef,
          id: cardData[i].id,
          title: cardData[i].title,
          artist: data.artistId.name,
          country: data.artistId.country,
          price: cardData[i].price
        });
      }

      setArtCards(artworkCards);
    } else {
      navigate("/artwork/"+artworkId+"/not-found");
    }   
  }

  const mintHandler = async () => {
    if(loginState && loginState.type === "metamask") {
      try {
        setMintLoader(true);
        await handleChainChange();

        console.log("MINT: ", artworkData.price, localStorage.getItem("account"), artworkData.tokenId, count);
        await mint(artworkData.price, localStorage.getItem("account"), artworkData.tokenId, count);

        toast("Purchase Successful!", {
          type: "success",
          toastId: mintToast
        });

        await getQuantity();
        setCount(1);

        setMintLoader(false);
      } catch (e) {
        console.log("MINT ERR: ", e.message, 'CODE:', e.code);

        toast(
          e.code === "INSUFFICIENT_FUNDS"
            ? "Wallet does not have enough balance"
            : e.message.includes("ERC20: transfer amount exceeds balance")
            ? "Not enough token balance"
            : e.code === "ACTION_REJECTED"
            ? "Transaction Rejected"
            : "Something went wrong :(",
          {
            type: "error",
            toastId: mintToast
          }
        );

        setMintLoader(false);
      }
    } 
    
    else if (!loginState) {
      setLoginModal(true);
    }
  }

  return (
    <div className="artwork-page">
      <section className="product-section">
        {artworkData ? <>
          <div className="trait-section">
            <img src={artworkData.image ? artworkData.image : blankArt} alt="artwork" className="art-img" />
            <div className="traits">
              <h1>Traits</h1>
              <div className="trait-grid">
                {artworkData.traits.map((trait, i) => {
                  return (
                    <div className="trait" key={i}>
                      <p>{trait.trait_type}</p>
                      <h2>{trait.value}</h2>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="info-section">
            <div className="title-container">
              <h1>{artworkData.title}</h1>
              <div className="marketplace-icons">
                {canBuy && (quantity < artworkData.quantity) ? 
                  <>
                    <p>View on</p>
                    <a href={process.env.REACT_APP_OPENSEA_LINK+artworkData.tokenId} target="_blank" rel="noopener noreferrer"><Opensea /></a>
                  </> : null
                }
              </div>
            </div>
            
            <p className="description">{artworkData.description}</p>

            <div className="buy-details">
              <p className="price">
                Price <br/> 
                <span className="eth">{artworkData.price} ETH</span> <span className="usd">{artworkData.priceUSD}</span>
              </p>
              <div className="qty">
                {canBuy && "Quantity"}
                <Counter 
                    artworkId={artworkId}
                    setCanBuy={setCanBuy}
                    maxCount={quantity}
                    setMaxCount={setQuantity}
                    price={0} 
                    count={count} 
                    setCount={setCount} 
                    initialQuantity={artworkData.quantity}
                />
              </div>
            </div>
            <span style={!canBuy || quantity === 0 ? {opacity: 0, pointerEvents: 'none'} : null}>
              {loginState && loginState.type === "google" ?
                  <div className="cross-mint-btn">
                      <BsCreditCard2BackFill /> Buy Now
                      <CrossmintPayButton
                          clientId={process.env.REACT_APP_CROSSMINT_CLIENT_ID}
                          environment={process.env.REACT_APP_CROSSMINT_ENVIRONMENT}
                          mintConfig={{
                              "type":" erc-1155",
                              "totalPrice": (artworkData.price * count).toString(),
                              "_id": artworkData.tokenId,
                              "_amount": count
                          }}
                      />
                  </div> : 
                  <button className={!loginState ? "buy-btn no-anim" : "buy-btn"} onClick={mintHandler}>{loginState.type === "metamask" && <FaEthereum />}Buy Now</button>
              }
            </span>
            
            <h1>About The Artist</h1>
            <div className="artist-info">
              <img src={artworkData.artist.image ? artworkData.artist.image : blankArtist} alt="artist" />
              <div>
                <h1>{artworkData.artist.name} <span>{artworkData.artist.country}</span></h1>
                <p>{artworkData.artist.description}</p>
                <Link className="view-artist-btn" to={"/artist/"+artworkData.artist.id}>View Profile</Link>
              </div>
            </div>
          </div>
        </> : <DataPlaceholder type="artwork" alternate /> }
      </section>
        
      
      <section className="artist-section">
        <div className="title">
          <h1>More {artworkData ? "from "+ artworkData.artist.name : null}</h1>
          {artworkData && <button onClick={() => navigate(`/artworks/${artworkData.artist.name}`)}>Browse More</button>}
        </div>
        <div className="art-grid">
          {artCards ? artCards.map((card, i) => {
            return (
              <ArtworkCard
                id={card.id} 
                image={card.image}
                title={card.title} 
                artist={card.artist} 
                country={card.country} 
                price={card.price}
                quickBuy={quickBuy}
                key={i}
              />
            )
          }) : [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card"/>}) }
        </div>
      </section>

      <div className="mint-loader" style={mintLoader ? null : {opacity: 0, pointerEvents: 'none', transform: 'translateY(1rem)'}}>
        <div className="prop-container">
          <img src={propCircle} alt="prop" className="circle" />
          <img src={propPlus} alt="prop" className="plus" />
        </div>
      </div> 

      <Footer />
    </div>
  )
}
