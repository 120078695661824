import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import "./artists.scss";

import { AiOutlineLoading } from "react-icons/ai";
import { MdClose } from "react-icons/md";

import { useGlobalSearch } from '../../Hooks/useGlobalSearch';

import ArtistCard from '../../Components/ArtistCard/ArtistCard';
import Footer from '../../Components/Footer/Footer';
import DropdownInput from '../../Components/DropdownInput/DropdownInput';
import DataPlaceholder from "../../Components/DataPlaceholder/DataPlaceholder";

export default function Artists() {
    const [artistCards, setArtistCards] = useState(null);
    const [sortQuery, setSortQuery] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataLoading, setDataLoading] = useState(false);

    const { searchQuery: routeSearchQuery } = useParams();
    const route = useLocation();
    const searchInput = useRef(null);

    const { data, fetching, hasMore } = useGlobalSearch("artist", searchQuery, sortQuery, pageNumber);

    const getPageData = async () => {
        if(pageNumber === 1) setArtistCards(null);
        if(data) {
            setDataLoading(true);
            const artistData = data;
        
            let artistCardsData = [];
            
            for(let i = 0; i < artistData.length; i++) {            
                artistCardsData.push({
                    id: artistData[i].id,
                    image: artistData[i].image,
                    name: artistData[i].name,
                    country: artistData[i].country,
                    description: artistData[i].description
                });
            }

            setArtistCards(artistCardsData);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        if (routeSearchQuery) {
            searchInput.current.value = routeSearchQuery;
            setSearchQuery(routeSearchQuery);
        } else {
            searchInput.current.value = null;
            setSearchQuery(null);
        }
    }, [route]);

    useEffect(() => {
        setPageNumber(1);
    }, [sortQuery, searchQuery]);

    useEffect(() => {
        getPageData();
    }, [data]);

    useEffect(() => {
        if(!data && !fetching) {
            setArtistCards([]);
        }
    }, [fetching]);

    return (
        <div className="artists">
            <div className="header">
                <h1>Artists</h1>

                <div className="filters">
                    <DropdownInput 
                        setValue={setSortQuery}
                        value={sortQuery}
                        options={[
                            {value: "MostRecent", title: "Most Recent"},
                            {value: "Oldest", title: "Least Recent"},
                            // {value: "Popularity", title: "Popularity"}
                        ]}
                    />
                    <span className="search-box">
                        <input type="text" placeholder="Search Artist" ref={searchInput} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                        <MdClose onClick={() => {searchInput.current.value = null; setSearchQuery(null);}} style={searchInput.current && searchInput.current.value ? null : {opacity: 0, pointerEvents: 'none'}}/>
                    </span>
                </div>
            </div>

            <div className="card-grid">
                {artistCards ? artistCards.length > 0 ? 
                    artistCards.map((card, i) => {return (
                        <ArtistCard 
                            id={card.id}
                            image={card.image}
                            name={card.name}
                            country={card.country}
                            description={card.description}
                            alternate
                            key={i}
                        />
                    )}) 
                    :   <div className="no-data">
                            <p>No Data Found</p>
                        </div> 
                    :   [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card" alternate/>}) 
                }
                <div className="load-btn-container">
                    {((artistCards && hasMore) || (artistCards && dataLoading)) &&
                        <button className="load-btn" onClick={() => {if(!fetching) setPageNumber(pageNumber + 1)}}>
                            {fetching || dataLoading ? <AiOutlineLoading /> : "Load More"}
                        </button>
                    }
                    {data && !hasMore && !fetching && <p></p> }
                </div>
            </div>
            <Footer />
        </div>
    )
}
