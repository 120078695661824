import React from 'react';
import Footer from '../../Components/Footer/Footer';

import "./faq.scss";

export default function FAQ() {

    const faqData = [
        {
            question: "What is Supermeta Store?",
            answer: ["Supermeta Store is a curated marketplace featuring NFTs, physical and digital artworks, collectibles and merchandise from selected Creators in our portfolio."]
        },
        {
            question: "Can I purchase any NFT using a credit card only?",
            answer: ["Yes! Just sign in with your Google account and pay via any Credit Card (Visa or Master)"]
        },
        {
            question: "What is “Crossmint” that is powering the credit card payments on Supermeta Store?",
            answer: ["Crossmint enables NFT purchases with credit cards (and other cryptocurrencies), and aims to make NFTs more accessible to everyone."]
        },
        {
            question: "I would like to find out more about Crossmint! Can you share more?",
            answer: ["Definitely! Feel free to check out their website here", "https://www.crossmint.io/"]
        },
        {
            question: "Do I need a Metamask or crypto wallet?",
            answer: ["No, you do not need a wallet initially when you are purchasing an NFT with Supermeta Store."]
        },
        {
            question: "Who and where are the artists/creators on Supermeta Store from?",
            answer: ["We curate leading and emerging Creators from around the world and select their best works for showcase and sale."]
        },
        {
            question: "What happens after I purchase an NFT from Supermeta Store?",
            answer: ["It will appear in your existing or newly created wallet for your enjoyment. You can also trade/sell the NFT in OpenSea."]
        },
        {
            question: "Will I be able to produce my purchased NFTs as merchandise i.e. T Shirts, hoodies, phone cases etc?",
            answer: ["Not yet, but we are working towards this very soon!"]
        },
        {
            question: "How is Supermeta Store related to Supermeta?",
            answer: ["Supermeta Store is part of the creative ecosystem created by Supermeta. Supermeta is a creative studio that develops phenomenal NFT experiences with exceptional creators and brands.", "https://www.supermetaplus.com/"]
        },
        {
            question: "If I have any further questions, who can i reach out to?",
            answer: ["You can drop us a message on our Discord, Twitter or email!", "https://discord.com/invite/supermetaplus", "https://twitter.com/supermetaplus_", "mailto:team@supermetaplus.com"]
        }
    ]

    return (
        <div className="faq">
            <div className="header">
                <h1>FAQ</h1>
            </div>

            <div className="tabs">
                {faqData.map((item, i) => {
                    return (
                        <div className="tab" key={i}>
                            <input type="checkbox" id={"chck"+i} />
                            <label className="tab-label" htmlFor={"chck"+i}>{(i+1) + ". " + item.question}</label>
                            <div className="tab-content">
                                {item.answer.map((a, j) => {
                                    if(a.includes("http") || a.includes("mailto:"))
                                        return (
                                            <a href={a} key={j}>{a.replace("mailto:", "")}<br/></a>
                                        )
                                    else
                                        return (
                                            <p key={j}>{a}<br/></p>
                                        )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            

            <Footer />
        </div>
    )
}
