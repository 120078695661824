import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "./manageArtists.scss";

import { FaTwitter, FaDiscord, FaInstagram, FaDeviantart, FaArtstation, FaBehance, FaDribbble, FaGlobe } from "react-icons/fa";

import { useAdminData } from "../../../Hooks/useAdminData";

import Footer from '../../../Components/Footer/Footer';

export default function ManageArtists() {
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState("published");
    const [artistData, setArtistData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const { data, fetching, hasMore } = useAdminData(currentTab, "artist", pageNumber);

    const getPageData = async () => {
        if(pageNumber === 1) setArtistData(null);
        if (data) {
            setDataLoading(true);
            const artistData = data;

            let artistTableData = [];
            
            for(let i = 0; i < artistData.length; i++) {              
                artistTableData.push({
                    id: artistData[i].id,
                    name: artistData[i].name,
                    country: artistData[i].country,
                    description: artistData[i].description,
                    discord: artistData[i].discord,
                    twitter: artistData[i].twitter,
                    website: artistData[i].website,
                    instagram: artistData[i].instagram,
                    deviantArt: artistData[i].deviantArt,
                    artStation: artistData[i].artStation,
                    behance: artistData[i].behance,
                    dribbble: artistData[i].dribble
                });
            }
    
            setArtistData(artistTableData);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        setArtistData(null);
        setPageNumber(1);
    }, [currentTab]);

    useEffect(() => {
        getPageData();
    }, [data]);

    return (
        <div className="manage-artists">
            <div className="header">
                <h1>Manage Artists</h1>
                <Link to={"/admin/manage-artworks"}><h2>Manage Artworks</h2></Link>
            </div>

            <div className="sub-header">
                <h2 className={currentTab === "published" ? "active" : ""} onClick={() => setCurrentTab("published")}>Published</h2>
                <h2 className={currentTab === "draft" ? "active" : ""} onClick={() => setCurrentTab("draft")}>Drafts</h2>
            </div>

            {artistData && artistData.length > 0 ?
                <table className="artists-table">
                    <thead>
                        <tr>
                            <th>Artist Name</th>
                            <th>Country</th>
                            <th>Description</th>
                            <th>Socials</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {artistData.map((artist, i) => {return (
                                <tr key={i}>
                                    <td className="name">{artist.name}</td>
                                    <td className="country">{artist.country}</td>
                                    <td className="description"><span>{artist.description}</span></td>
                                    <td className="socials">
                                        {artist.twitter ? <a target="_blank" rel="noopener noreferrer" href={artist.twitter}><FaTwitter /></a> : null}
                                        {artist.discord ? <a target="_blank" rel="noopener noreferrer" href={artist.discord}><FaDiscord /></a> : null}
                                        {artist.instagram ? <a target="_blank" rel="noopener noreferrer" href={artist.instagram}><FaInstagram /></a> : null}
                                        {artist.deviantArt ? <a target="_blank" rel="noopener noreferrer" href={artist.deviantArt}><FaDeviantart /></a> : null}
                                        {artist.artStation ? <a target="_blank" rel="noopener noreferrer" href={artist.artStation}><FaArtstation /></a> : null}
                                        {artist.behance ? <a target="_blank" rel="noopener noreferrer" href={artist.behance}><FaBehance /></a> : null}
                                        {artist.dribbble ? <a target="_blank" rel="noopener noreferrer" href={artist.dribbble}><FaDribbble /></a> : null}
                                        {artist.website ? <a target="_blank" rel="noopener noreferrer" href={artist.website}><FaGlobe /></a> : null}
                                    </td>
                                    <td className="action"><button onClick={() => navigate("/admin/edit-artist/"+artist.id)}>{currentTab === "published" ? "Edit" : "Complete"}</button></td>
                                </tr>
                            )}) 
                        }
                        {hasMore && <tr className="load-more">
                            <td colSpan="5"><p onClick={() => setPageNumber(pageNumber+1)}>Load More</p></td>
                        </tr>}
                    </tbody>
                </table> :
                <div className="no-data">
                    {currentTab === "published" && !(dataLoading || fetching) && <>
                        <p>You have not published any artist yet.</p>
                        <button onClick={() => navigate("/admin/add-artist")}>Add Now</button>
                    </>}
                    {currentTab === "draft" && !(dataLoading || fetching) && <>
                        <p>No drafts so far.</p>
                    </>}
                    {(dataLoading || fetching) && !artistData && <p>Fetching {currentTab} artists...</p>}
                </div>
            }
            <Footer />
        </div>
    )
}
