import React from 'react';

import "./notFound.scss";

import propCircle from "../../Assets/prop-dashed-circle.svg";
import propPlus from "../../Assets/prop-plus.svg";
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="not-found">
      <span className="center-stage">
        4
        <div className="prop-container">
          <img src={propCircle} alt="prop" className="circle" />
          <img src={propPlus} alt="prop" className="plus" />
        </div>
        4
      </span>
      <h2>Page not found!</h2>
      <button onClick={() => {
        navigate("/");
      }}>Go Home</button>
    </div>
  )
}
