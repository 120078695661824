import { ethers } from "ethers";

const signMessage = async (data) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found. Please install it.");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const message = `{
      address: ${data},
      timestamp: ${parseInt(Date.now() / 1000)},
    }`;
    const signature = await signer.signMessage(message);
    const wallet_address = await signer.getAddress();
    return {
      message,
      signature,
      wallet_address,
    };
  } catch (err) {
    if (err.code === "ACTION_REJECTED")
    throw "Login request rejected";
    else {
      console.log(err.message);
      throw "Something went wrong";
    }
  }
};

export default signMessage;