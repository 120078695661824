import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import "./artworks.scss";

import { AiOutlineLoading } from "react-icons/ai";
import { MdClose } from "react-icons/md";

import { ApiCall } from '../../Utils/ApiUtils';
import { useGlobalSearch } from '../../Hooks/useGlobalSearch';

import ArtworkCard from '../../Components/ArtworkCard/ArtworkCard';
import Footer from '../../Components/Footer/Footer';
import DropdownInput from '../../Components/DropdownInput/DropdownInput';
import DataPlaceholder from '../../Components/DataPlaceholder/DataPlaceholder';

export default function Artworks({ quickBuy }) {
    const [artworkCards, setArtworkCards] = useState(null);
    const [sortQuery, setSortQuery] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataLoading, setDataLoading] = useState(false);

    const { searchQuery: routeSearchQuery } = useParams();
    const route = useLocation();
    const searchInput = useRef(null);

    const { data, fetching, hasMore } = useGlobalSearch("artwork", searchQuery, sortQuery, pageNumber);

    const getPageData = async () => {
        if(pageNumber === 1) setArtworkCards(null);
        if (data) {
            setDataLoading(true);
            const artworkData = data;

            let artworkCardData = [];
            
            for(let i = 0; i < artworkData.length; i++) {              
                artworkCardData.push({
                    id: artworkData[i].id,
                    image: artworkData[i].imageRef,
                    title: artworkData[i].title,
                    artist: artworkData[i].artistName,
                    country: artworkData[i].country,
                    price: artworkData[i].price
                });
            }
    
            setArtworkCards(artworkCardData);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        if (routeSearchQuery) {
            searchInput.current.value = routeSearchQuery;
            setSearchQuery(routeSearchQuery);
        } else {
            searchInput.current.value = null;
            setSearchQuery(null);
        }
    }, [route]);
    
    useEffect(() => {
        setPageNumber(1);
    }, [sortQuery, searchQuery]);

    useEffect(() => {
        if (searchQuery && searchQuery.length > 0)
            setSortQuery("MostMatched");
        else 
            setSortQuery("MostRecent")
    }, [searchQuery]);

    useEffect(() => {
        getPageData();
    }, [data]);

    useEffect(() => {
        if(!data && !fetching) {
            setArtworkCards([]);
        }
    }, [fetching]);

    return (
        <div className="artworks">
            <div className="header">
                <h1>Artworks</h1>

                <div className="filters">
                    <DropdownInput 
                        value={sortQuery}
                        setValue={setSortQuery}
                        options={searchInput.current && searchInput.current.value ? 
                            [
                                {value: "MostMatched", title: "Most Relevant"},
                                {value: "MostRecent", title: "Most Recent"},
                                {value: "Oldest", title: "Least Recent"},
                                {value: "PriceHighToLow", title: "Price: High to Low"},
                                {value: "PriceLowToHigh", title: "Price: Low to High"}
                            ] : 
                            [
                                {value: "MostRecent", title: "Most Recent"},
                                {value: "Oldest", title: "Least Recent"},
                                {value: "PriceHighToLow", title: "Price: High to Low"},
                                {value: "PriceLowToHigh", title: "Price: Low to High"}
                            ]
                        }
                    />
                    <span className="search-box">
                        <input type="text" placeholder="Search Artwork" ref={searchInput} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                        <MdClose onClick={() => {searchInput.current.value = null; setSearchQuery(null);}} style={searchInput.current && searchInput.current.value ? null : {opacity: 0, pointerEvents: 'none'}}/>
                    </span>
                </div>
            </div>

            <div className="card-grid">
                {artworkCards ? artworkCards.length > 0 ? 
                    artworkCards.map((card) => {return (
                            <ArtworkCard 
                                id={card.id}
                                image={card.image} 
                                title={card.title} 
                                artist={card.artist} 
                                country={card.country} 
                                price={card.price}
                                quickBuy={quickBuy}
                                alternate
                                key={card.id}
                            />
                        )}) 
                    :   <div className="no-data">
                            <p>No Data Found</p>
                        </div> 
                    :   [...Array(5).keys()].map((i) => {return <DataPlaceholder key={i} type="card" alternate />}) 
                }
                
                <div className="load-btn-container">
                    {((artworkCards && hasMore) || (artworkCards && dataLoading)) &&
                        <button className="load-btn" onClick={() => {if(!fetching) setPageNumber(pageNumber + 1)}}>
                            {fetching || dataLoading ? <AiOutlineLoading /> : "Load More"}
                        </button>
                    }
                    {data && !hasMore && !fetching && <p></p> }
                </div>
            </div>
            <Footer />
        </div>
    )
}
