import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import "./editArtist.scss"

import { IoCloudUpload } from "react-icons/io5";
import { AiOutlineLoading } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { FaTwitter, FaDiscord, FaInstagram, FaDeviantart, FaArtstation, FaBehance, FaDribbble, FaGlobe } from "react-icons/fa";

import countries from "../../../Utils/countries";
import Select from 'react-select';

import { artistSchema } from '../../../Utils/validationSchema';
import { ApiCall } from "../../../Utils/ApiUtils";
import { AdminApiCall, ApiFileUpload } from '../../../Utils/AdminApiUtils';

export default function EditArtist() {
    const { id: artistId } = useParams();
    const navigate = useNavigate();
    let editArtistToast = React.useRef(null);
    const toastId = "toast";

    const [profileImage, setProfileImage] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageId, setImageId] = useState(null);

    const [dataLoading, setDataLoading] = useState(true);
    const [existingData, setExistingData] = useState();

    const [formChanged, setFormChanged] = useState(false);

    const [validationError, setValidationError] = useState({
        image: false,
        name: false,
        country: false,
        description: false,
        walletAddress: false,
        discord: false,
        twitter: false,
        website: false,
        instagram: false,
        deviantArt: false,
        artStation: false,
        behance: false,
        dribble: false
    });

    const handleImageUpload = async (e) => {
        setUploadingImage(true);
        setProfileImage(null);
        if (e.target.files[0])
            try {
                if(e.target.files[0].size > 31457280) {
                    toast("File size bigger than 30MB", {
                        type: 'error'
                    });
                    setUploadingImage(false);
                    return;
                }
                if(!e.target.files[0].type.includes('image')) {
                    toast("Invalid file type.", {
                        type: 'error'
                    });
                    setUploadingImage(false);
                    return;
                }
                const imageResp = await ApiFileUpload(e.target.files[0]);
                setImageId(imageResp.id);
                setProfileImage(URL.createObjectURL(e.target.files[0]));
            } catch (e) {
                toast("Upload Error: "+e, {type: 'error'});
                console.log("Upload Error: ", e);
            }
        setUploadingImage(false);
    }

    const handleUpdateArtist = async (event) => {
        event.preventDefault();
        setValidationError({
            image: false,
            name: false,
            country: false,
            description: false,
            walletAddress: false,
            discord: false,
            twitter: false,
            website: false,
            instagram: false,
            deviantArt: false,
            artStation: false,
            behance: false,
            dribble: false
        });

        let formData = {
            id: artistId,
            name: event.target.artistName.value,
            country: event.target.artistCountry.value,
            description: event.target.artistDescription.value,
            // walletAddress: event.target.walletAddress.value,
            isFeatured: event.target.isFeatured.checked,
            progressStatus: event.nativeEvent.submitter.value === "cancel" ? existingData.progressStatus : event.nativeEvent.submitter.value
        }
        formData.discord = event.target.discord.value;
        formData.twitter = event.target.twitter.value;
        formData.website = event.target.website.value;
        formData.instagram = event.target.instagram.value;
        formData.deviantArt = event.target.deviantArt.value;
        formData.artStation = event.target.artStation.value;
        formData.behance = event.target.behance.value;
        formData.dribble = event.target.dribbble.value;

        if (imageId) formData.image = imageId;

        if (event.nativeEvent.submitter.value === "cancel") {
            navigate("/admin/manage-artists");
            return;
        }

        console.log(formData);
        editArtistToast.current = toast("Validating", {
            toastId: toastId,
            isLoading: true
        });
        try {
            await artistSchema.validate(formData, {
                abortEarly: false
            });
            toast.update(editArtistToast.current, {
                render: "Saving", 
                isLoading: true
            }); 
            
            await AdminApiCall("POST", "/rest/artist/updateArtist", formData).then((resp) => {
                // console.log(resp);
                if (formData.progressStatus === "draft")
                    setTimeout(() => {
                        toast.update(editArtistToast.current, {
                            render: formData.name + " saved as draft.",
                            type: 'success',
                            isLoading: false,
                            closeButton: true,
                            autoClose: 3000
                        });
                    }, 500);
                else
                    setTimeout(() => {
                        toast.update(editArtistToast.current, {
                            render: formData.name + " published successfully.",
                            type: 'success',
                            isLoading: false,
                            closeButton: true,
                            autoClose: 3000,
                        });
                    }, 500);
                if(event.nativeEvent.submitter.value === "production")
                    navigate("/admin/manage-artists");
                else
                    setFormChanged(false);
            }).catch(e => {
                toast.update(editArtistToast.current, {
                    render: e.response.data.message, type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
                console.log(e);
            });
        } catch (e) {
            if (e.toString().includes("ValidationError")) {
                let validationErr = {}
                for (let i = 0; i < e.inner.length; i++) {
                    validationErr[e.inner[i].path] = e.inner[i].message;
                }
                setValidationError(validationErr);
                console.log(validationErr);
                toast.update(editArtistToast.current, {
                    render: "Please fill required fields correctly", 
                    type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
            }
            else {
                toast.update(editArtistToast.current, {
                    render: e.message, 
                    type: 'error', 
                    isLoading: false,
                    closeButton: true
                });
                console.log(e);
            }
        }            
    }

    const getExistingData = async () => {
        const data = await ApiCall("GET", "/rest/artist/getArtistById/"+artistId);
        if (data) {
            setExistingData(data);
            setImageId(data.image ? data.image : null);
            
            setDataLoading(false);

            const image = await ApiCall("GET", "/rest/file-manager/getUrlById/"+data.image);
            setProfileImage(image);
        }
    }

    useEffect(() => {
        getExistingData();
    }, []);

    return (
        <div className="edit-artist">
            <div className="header">
                <h1>Edit Artist</h1>
            </div>

            {dataLoading ? 
                <div className="data-loading">
                    <p>Fetching Data...</p>
                </div> :
                <form onSubmit={handleUpdateArtist}>
                    <div className="artist-info">
                        <label htmlFor="profile-img" className="profile-img">
                            {uploadingImage ? 
                                <div className="upload-loader">
                                    <AiOutlineLoading />
                                    Uploading
                                </div> :
                                <>
                                    <IoCloudUpload />
                                    Upload Profile Picture
                                </>
                            }
                            <input type="file" accept="image/*" name="profileImg" onChange={(e) => {handleImageUpload(e); setFormChanged(true);}}/>
                            {profileImage && <img src={profileImage} className='profile-img-thumb'/>}
                            {validationError.image && <span className="error">
                                <MdError />
                                {validationError.image}
                            </span>}
                        </label>

                        <span>
                            <label htmlFor="artist-name">
                                <p>Artist Name <span className="required">*</span></p> 
                                <input type="text" name="artistName" maxLength="32" defaultValue={existingData.name} onChange={() => setFormChanged(true)}/>
                                {validationError.name && <span className="error">
                                    <MdError />
                                    {validationError.name}
                                </span>}
                            </label>

                            <label htmlFor="artist-country">
                                <p>Country<span className="required">*</span></p> 
                                <Select 
                                    options={countries} 
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.code}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    unstyled
                                    placeholder={"Select Country"}
                                    name="artistCountry"
                                    onChange={() => setFormChanged(true)}
                                    defaultValue={{
                                        code: existingData.country, 
                                        name: countries.filter(country => {return country.code === existingData.country})[0].name
                                    }}
                                />
                                {validationError.country && <span className="error">
                                    <MdError />
                                    {validationError.country}
                                </span>}
                            </label>

                            <label htmlFor="artist-description" className="description">
                                <p>Artist Description<span className="required">*</span></p>
                                <textarea name="artistDescription" id="artist-des" cols="30" maxLength="500" defaultValue={existingData.description} onChange={() => setFormChanged(true)}/>
                                {validationError.description && <span className="error">
                                    <MdError />
                                    {validationError.description}
                                </span>}
                            </label>
                        </span>
                    </div>

                    {/* <div className="wallet-info">
                        <label>
                            <p>
                                Wallet Address
                                <span className="required">*</span>
                            </p>
                        </label>
                        <input type="text" name='walletAddress' placeholder='Wallet Address' defaultValue={existingData.walletAddress} onChange={() => setFormChanged(true)}/>
                        {validationError.walletAddress && <span className="error">
                            <MdError />
                            {validationError.walletAddress}
                        </span>}
                    </div> */}

                    <div className="social-info">
                        <label>Social Links</label>
                        <span>
                            <span className="social-input">
                                <FaDiscord />
                                <input type="text" name='discord' placeholder='Discord' defaultValue={existingData.discord} onChange={() => setFormChanged(true)}/>
                                {validationError.discord && <span className="error">
                                    <MdError />
                                    {validationError.discord}
                                </span>}
                            </span>
                            <span className="social-input">
                                <FaTwitter />
                                <input type="text" name='twitter' placeholder='Twitter' defaultValue={existingData.twitter} onChange={() => setFormChanged(true)}/>
                                {validationError.twitter && <span className="error">
                                    <MdError />
                                    {validationError.twitter}
                                </span>}
                            </span>
                        </span>
                        
                        <span className="social-input">
                            <FaGlobe />
                            <input type="text" name='website' placeholder='Website' defaultValue={existingData.website} onChange={() => setFormChanged(true)}/>
                            {validationError.website && <span className="error">
                                <MdError />
                                {validationError.website}
                            </span>}
                        </span>
                        <span className="social-input">
                            <FaInstagram />
                            <input type="text" name='instagram' placeholder='Instagram' defaultValue={existingData.instagram} onChange={() => setFormChanged(true)}/>
                            {validationError.instagram && <span className="error">
                                <MdError />
                                {validationError.instagram}
                            </span>}
                        </span>
                        <span className="social-input">
                            <FaDeviantart />
                            <input type="text" name='deviantArt' placeholder='DeviantArt' defaultValue={existingData.deviantArt} onChange={() => setFormChanged(true)}/>
                            {validationError.deviantArt && <span className="error">
                                <MdError />
                                {validationError.deviantArt}
                            </span>}
                        </span>
                        <span className="social-input">
                            <FaArtstation />
                            <input type="text" name='artStation' placeholder='ArtStation' defaultValue={existingData.artStation} onChange={() => setFormChanged(true)}/>
                            {validationError.artStation && <span className="error">
                                <MdError />
                                {validationError.artStation}
                            </span>}
                        </span>
                        <span className="social-input">
                            <FaBehance />
                            <input type="text" name='behance' placeholder='Behance' defaultValue={existingData.behance} onChange={() => setFormChanged(true)}/>
                            {validationError.behance && <span className="error">
                                <MdError />
                                {validationError.behance}
                            </span>}
                        </span>
                        <span className="social-input">
                            <FaDribbble />
                            <input type="text" name='dribbble' placeholder='Dribbble' defaultValue={existingData.dribble} onChange={() => setFormChanged(true)}/>
                            {validationError.dribble && <span className="error">
                                <MdError />
                                {validationError.dribble}
                            </span>}
                        </span>
                    </div>

                    <div className="featured">
                        <input type="checkbox" name="isFeatured" defaultChecked={existingData.isFeatured} onChange={() => setFormChanged(true)}/>
                        <label>Featured</label>
                    </div>

                    <div className="btn-container">
                        {/* <button className="cancel" type="submit" value="cancel">Cancel</button> */}
                        {existingData.progressStatus === "draft" && <button className="save-btn" type="submit" value="draft" disabled={!formChanged}>Save Draft</button>}
                        <button className="publish" type="submit" value="production">{existingData.progressStatus === "draft" ? "Publish" : "Update"}</button>
                    </div>
                </form>
            }
        </div>
    )
}
