import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cutAddress } from '../../Utils/common';
import { useAuth } from '../../Hooks/useAuth';

import "./navbar.scss";

import { MdLogout } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";

export default function Navbar() {
  const currentRoute = useLocation();
  const { loginState, googleStatus, metamaskStatus, setLoginModal } = useAuth();

  const [adminState, setAdminState] = useState(false);

  useEffect(() => {
    if (currentRoute.pathname.includes("/admin")) setAdminState(true);
    else setAdminState(false);
  }, [currentRoute])

  return (
    <nav>
        <h1 className="logo"><Link to="/">SUPERMETA {adminState ? <span className="admin-chip">Admin</span>:<span>STORE</span>}</Link></h1>
        <div className="nav-links">
          {!adminState ?
            <>
              <Link to="/discover">Discover</Link>
              <Link to="/artworks">Artworks</Link>
              <Link to="/artists">Artists</Link>
              <Link to="/about">About Us</Link>
            </> : 
            <>
              {currentRoute.pathname.includes("manage") && <Link className="admin-link" to={"/admin/add-artist"}>Add Artist/Artwork</Link>}
              {(currentRoute.pathname.includes("add") || currentRoute.pathname.includes("edit")) && <Link className="admin-link" to={"/admin/manage-artists"}>Manage Artists/Artworks</Link>}
            </>
          }
          {adminState ? 
              loginState ?
              <p className="admin-account">
                {(googleStatus === "initializing" || metamaskStatus === "initializing") ?
                  <span className="loader"><AiOutlineLoading /></span> :
                  <>
                    Logged in as 
                    <span onClick={() => setLoginModal(true)}>
                      {loginState.type === "metamask" ? 
                          cutAddress(loginState.account) : 
                            loginState.account} 
                      <MdLogout />
                    </span>
                  </>
                }
              </p> : (googleStatus === "initializing" || metamaskStatus === "initializing") && <p className="admin-account"><span className="loader"><AiOutlineLoading /></span></p> :
              <button className="login-btn" onClick={() => {
                if(!(googleStatus === "initializing" || metamaskStatus === "initializing"))
                  setLoginModal(true);
              }}>
                <>
                  {(googleStatus === "initializing" || metamaskStatus === "initializing") ? 
                    <AiOutlineLoading /> : 
                    loginState ? 
                      loginState.type === "metamask" ? <p>{cutAddress(loginState.account)}</p> : <p>{loginState.account}</p>
                  : <p className="login-p">Login</p>}
                </>
              </button>              
          }
        </div>
    </nav>
  )
}
