export const getListedEmail = (name, email, country, portfolio, remarks) => {
    return (
        `<table style="border-collapse:collapse;border-spacing:0;table-layout: fixed; width: 564px" class="tg"><colgroup><col style="width: 124.2px"><col style="width: 440.2px"></colgroup><thead><tr><th style="background-color:#000000;border-color:#000000;border-style:solid;border-width:0px;color:#ffffff;font-family:Arial, sans-serif;font-size:26px;font-weight:normal;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal" colspan="2">Hey!</th></tr></thead><tbody><tr><td style="background-color:#000000;border-color:#000000;border-style:solid;border-width:0px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:top;word-break:normal" colspan="2">An artist has sent a request to get listed on supermeta store. Here are the details;</td></tr><tr><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:middle;word-break:normal">Name</td><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal">`
        + name + 
        `</td></tr><tr><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:middle;word-break:normal">Email</td><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal">`
        + email + 
        `</td></tr><tr><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:middle;word-break:normal">Country</td><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal">`
        + country + 
        `</td></tr><tr><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;color:#000000;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:middle;word-break:normal">Portfolio</td><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;color:#000000;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal">`
        + portfolio + 
        `</td></tr><tr><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;color:#000000;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:middle;word-break:normal">Remarks</td><td style="background-color:#ffffff;border-color:#ffffff;border-style:solid;border-width:0px;color:#000000;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:bottom;word-break:normal">`
        + remarks + 
        `</td></tr><tr><td style="background-color:#000000;border-color:inherit;border-style:solid;border-width:0px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:12px 12px;text-align:left;vertical-align:top;word-break:normal" colspan="2">This is an automated email from Supermeta Store.</td></tr></tbody></table>`
    )
}