import React from 'react'

import "./dataPlaceholder.scss";

export default function DataPlaceholder({ type, alternate }) {
    
    if(type === "card") 
        return (
            <div className="card-placeholder" style={alternate ? {filter: 'invert(1)'} : null}></div>
        )
    if(type === "artwork")
        return (
            <div className="artwork-placeholder" style={alternate ? {filter: 'invert(1)'} : null}>
                <div className="image"></div>

                <div className="side-section">
                    <div className="heading"></div>
                    <div className="description">
                        <p></p>
                        <p></p>
                    </div>
                    <div className="price">
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
        )
    if(type === "quickbuy")
        return (
            <div className="quickbuy-placeholder" style={alternate ? {filter: 'invert(1)'} : null}>
                <div className="image"></div>

                <div className="side-section">
                    <div className="heading"></div>
                    <p className="artist"></p>
                    <div className="description">
                        <p></p>
                        <p></p>
                    </div>
                    <div className="price">
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
        )
    if(type === "artist")
        return (
            <div className="artist-placeholder" style={alternate ? {filter: 'invert(1)'} : null}>
                <div className="image"></div>
            </div>
        )
    if(type === "discover")
        return (
            <tr className="discover-placeholder">
                <td className="serial"></td>
                <td className="image"></td>
                <td className="name">
                    <h2></h2>
                    <p></p>
                </td>
                <td className="description">
                    <p></p>
                    <p></p>
                    <p></p>
                </td>
                <td className="price"><br/><span></span></td>
                {/* <td className="date"></td> */}
                <td className="btn"></td>
            </tr>
        )
}
