import React, { useEffect, useState } from 'react';

import './counter.scss';

import { ApiCall } from '../../Utils/ApiUtils';

export default function Counter({ artworkId, setCanBuy, maxCount, setMaxCount, count, setCount, initialQuantity }) {
    const [fetchingData, setFetchingData] = useState(true);

    const getQuantity = async () => {
        const artworkQuantity = await ApiCall("GET", "/rest/contract-interaction/getTokenLeft/"+artworkId);
        setMaxCount(artworkQuantity.numberOfItemLeft);
        setCanBuy(true);
        setFetchingData(false);
    }

    useEffect(() => {
        setCanBuy(false);
        setFetchingData(true);
        getQuantity();
    }, []);

    const handleCounterIncrease = () => {
        if(count < maxCount) {
            setCount(count + 1);
        }
        else
            return
    }
    
    const handleCounterDecrease = () => {
        if(count > 1) {
            setCount(count - 1);
        }
        else
            return
    }

    return (
        <span className="counter">
            {fetchingData ? null :
                maxCount > 0 ? 
                    <>
                        <p className="btn" onClick={() => handleCounterDecrease()}>-</p>
                        <p>{count} / {maxCount}</p>
                        <p className="btn" onClick={() => handleCounterIncrease()}>+</p>
                    </> : 
                    <p className="stock-error">All Minted / {initialQuantity}</p>
            }
        </span>
    )
}
