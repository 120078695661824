import axios from "axios";

export const AdminAuth = async (method = "GET", token, data = {}) => {
  try {
    const res = await axios({
      method,
      url: process.env.REACT_APP_API_DATA_URL + "/rest/auth/isAdmin",
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    if(e.response.data.name === "UNAUTHORIZED") {
      return "expired";
    } else {
      console.log("API "+method+" ERR: ", e);
      return e;
    }
  }
};

export const AdminApiCall = async (method = "POST", path, data = {}) => {
  const token = localStorage.getItem("adminToken");
  try {
    const res = await axios({
      method,
      url: process.env.REACT_APP_API_DATA_URL + path,
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    throw e
  }
};

export const ApiFileUpload = async (file) => {
  const fileFormData = new FormData();
  fileFormData.append("file", file, file.name);
  const token = localStorage.getItem("adminToken");
  const res = await axios.post(`${process.env.REACT_APP_API_DATA_URL}/rest/file-manager/upload`, fileFormData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
};