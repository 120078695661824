import React from 'react'

import "./footer.scss";

import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
        <div className="section-a">
            <h2>All Rights Reserved, {new Date().getFullYear()}</h2>
            <h1 className="logo">SUPERMETA STORE</h1>
        </div>
        <div className="section-b">
            <ul className="links">
                <li><Link to="/about">About Us</Link><span className="divider">•</span></li>
                <li><a href="https://www.supermetaplus.com/contact" target="_blank" rel="noopener noreferrer">Contact</a><span className="divider">•</span></li>
                <li><Link to="/faq">FAQ</Link><span className="divider">•</span></li>
                <li><a href="https://www.supermetaplus.com/terms" target="_blank" rel="noopener noreferrer">Terms of Services</a><span className="divider">•</span></li>
                <li><Link to="/get-listed">Get Listed</Link></li>
            </ul>
            <ul className="social-icons">
                <li><a href="https://discord.gg/supermetaplus" target="_blank" rel="noopener noreferrer"><FaDiscord /></a></li>
                <li><a href="https://twitter.com/supermetaplus_" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/supermetaplus/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
            </ul>
        </div>
    </footer>
  )
}
