import { useEffect, useState } from 'react';

import {
    Navigate,
    Outlet,
    useLocation,
    useNavigate
} from 'react-router-dom';

import { AdminAuth } from '../../Utils/AdminApiUtils';

export default function ProtectedRoute ({ adminLoginState, redirectPath = '/admin', children }) {
  const currentPath = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (adminLoginState || adminToken) {
      (async () => {
        const isAdmin = await AdminAuth("GET", adminToken);
        if (isAdmin === "expired" || !isAdmin) {
          return navigate(redirectPath, {replace: true});
        }
      })();
    } else {
      return navigate(redirectPath, {replace: true});
    }
  }, [currentPath]);

  return children ? <>{children()}</> : <Outlet />;
};