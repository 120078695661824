import * as yup from "yup";

export const artistSchema = yup.object().shape({
    image: yup.string().required('Please Upload Profile Image'),
    name: yup.string().required('Artist Name Required'),
    country: yup.string().max(2, 'Select Artist Country').required('Select Artist Country'),
    description: yup.string().max(500, 'Description exceeds max character limit of 500.').required('Artist Description Required'),
    discord: yup.string().matches('discord', 'Invalid Discord URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    twitter: yup.string().matches('twitter', 'Invalid Twitter URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    website: yup.string().url('Enter a valid URL').optional(),
    instagram: yup.string().matches('instagram', 'Invalid Instagram URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    deviantArt: yup.string().matches('deviantart', 'Invalid DeviantArt URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    artStation: yup.string().matches('artstation', 'Invalid ArtStation URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    behance: yup.string().matches('behance', 'Invalid Behance URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    dribble: yup.string().matches('dribbble', 'Invalid Dribbble URL').url('Enter a valid URL').optional().nullable().transform((value) => !!value ? value : null),
    progressStatus: yup.string(),
    isFeatured: yup.boolean()
});

export const prodArtworkSchema = yup.object().shape({
    imageRef: yup.string().required('Please Upload Artwork Image'),
    title: yup.string().required('Artwork Title Required'),
    description: yup.string().max(500, 'Description exceeds max character limit of 500.').required('Artwork Description Required'),
    itemsSold: yup.number().optional().nullable(),
    quantity: yup.string().max(5, 'Quantity cannot be more than 5 digits').test(
        'Is positive',
        'Quantity cannot be a negative number',
        (value) => value > 0
    ).test("more than sold", function (value) {
        let sold = this.resolve(yup.ref("itemsSold"));
        if (value < sold) {
          return this.createError({
            message: `Can't be less than no. of sold items (${sold})`,
            path: "quantity", // Fieldname
          });
        } else return true;
    }).required('Artwork Quantity Required'),
    artistId: yup.string().required('Please select artist'),
    traits: yup.array().of(
        yup.object().shape({
            trait_type: yup.string().required('Select Trait Type'),
            value: yup.string().required('Enter Trait Value')
        }) 
    ),
    price: yup.string().test(
            'Is positive',
            'Price cannot be a negative number',
            (value) => value > 0
        ).test(
            'Is too long ( ͡° ͜ʖ ͡°)',
            'Price cannot be more than 10 digits',
            (value) => value.toString().includes('.') ? value.toString().length <= 11 : value.toString().length <= 10
        ).required('Artwork Price Required'),
    progressStatus: yup.string(),
    isFeatured: yup.boolean(),
    isTrending: yup.boolean()
});

export const draftArtworkSchema = yup.object().shape({
    imageRef: yup.string(),
    title: yup.string().required('Artwork Title Required'),
    description: yup.string().max(500, 'Description exceeds max character limit of 500.'),
    quantity: yup.string().max(5, 'Quantity cannot be more than 5 digits').test(
        'Is positive',
        'Quantity cannot be a negative number',
        (value) => !!value ? value > 0 : true
    ).nullable().transform((value) => !!value ? value : null),
    artistId: yup.string(),
    traits: yup.array().of(
        yup.object().shape({
            trait_type: yup.string(),
            value: yup.string()
        }) 
    ),
    price: yup.string().test(
            'Is positive',
            'Price cannot be a negative number',
            (value) => !!value ? value > 0 : true
        ).test(
            'Is too long ( ͡° ͜ʖ ͡°)',
            'Price cannot be more than 10 digits',
            (value) => !!value ? value.toString().includes('.') ? value.toString().length <= 11 : value.toString().length <= 10 : true
        ).nullable().transform((value) => !!value ? value : null),
    progressStatus: yup.string(),
    isFeatured: yup.boolean(),
    isTrending: yup.boolean()
});

export const getListedSchema = yup.object().shape({
    name: yup.string().required('Name required'),
    email: yup.string().email('Enter a valid email address').required('Email required'),
    country: yup.string().required('Select country'),
    portfolio: yup.string().url('Enter a valid URL').required('Portfolio link required'),
    remarks: yup.string().max(500, 'Additional remarks exceeds max character limit of 500.').required('Additional remarks required'),
});
