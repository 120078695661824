import { toast } from "react-toastify";

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const convertToSubString = (str, to) => {
    const newString = str.substring(0, 20) + "..." + str.substring(str.length - 10, str.length);
    return str.length > 25 ? newString : str;
};

export const cutAddress = (account) => {
    return account?.substring(0, 5) + "...." + account?.substring(account.length - 4);
};

export const copyTextToClipboard = async (textToCopy, successMessage) => {
    if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(textToCopy);
    //   return toast.success(successMessage);
    } else {
        return document.execCommand("copy", true, textToCopy);
    }
};

export const handleChainChange = async (reload) => {
    const currentChain = await window.ethereum.request({
      method: "eth_chainId",
    });

    const chainID = process.env.REACT_APP_CHAIN_ID;

    if (currentChain !== `0x${chainID}`) {

      toast("Switch to " + process.env.REACT_APP_NETWORK, {
        position: "top-center",
        type: "warning",
        autoClose: 5000,
        hideProgressBar: true,
        toastId: currentChain,
      });

      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainID}` }],
        })
        .then(() => {
          if (reload) window.location.reload(false);
        });
    }
};